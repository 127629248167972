import moment from 'moment-timezone';
import i18next from 'i18next';
import { ResponseStatusCode } from './enums/http-response';

export const timeZoneDisplayName = (timezone: string): string => {
  if (timezone) {
    // We will get the UTC offset in minutes and devide by 60 to get in hours
    const offset: number = moment.tz(timezone).utcOffset() / 60;

    // The offset is + or - time in minutes. we will check the sign
    const sign: string = offset >= 0 ? '+' : '-';

    // We will take the absolute value of a number.
    const absOffset: number = Math.abs(offset);

    return `UTC${sign}${absOffset} (${timezone})`;
  } else {
    return 'UTC (universal time)';
  }
};

export const errorCodeMessage = (errorCode: number, textToChain?: string): string => {
  const textToChainFixed: string = textToChain || '';

  if (errorCode === ResponseStatusCode.NotFound) {
    return `${textToChainFixed} ${i18next.t('pages.common.errorsMessages.status404')}`.trim();
  } else if (errorCode === ResponseStatusCode.BadRequest) {
    return `${textToChainFixed} ${i18next.t('pages.common.errorsMessages.status400')}`.trim();
  } else if (errorCode === ResponseStatusCode.ServerError) {
    return `${textToChainFixed} ${i18next.t('pages.common.errorsMessages.status500')}`.trim();
  } else if (errorCode === ResponseStatusCode.RateLimit) {
    return `${textToChainFixed} ${i18next.t('pages.common.errorsMessages.status429')}`.trim();
  } else if (errorCode === ResponseStatusCode.InvalidTokenOrSession) {
    return `${textToChainFixed} ${i18next.t('pages.common.errorsMessages.status401')}`.trim();
  } else if (errorCode === ResponseStatusCode.AccessDenied) {
    return `${textToChainFixed} ${i18next.t('pages.common.errorsMessages.status403')}`.trim();
  } else {
    return `${textToChainFixed} ${i18next.t('pages.common.errorsMessages.other')}`.trim();
  }
};
