const accountSettings = {
  title: 'account settings',

  userDetails: {
    userInfoTitle: 'User information',
    timezoneTitle: 'Location and Timezone',

    details: {
      name: 'User name:',
      email: 'email:',
      phone: 'Phone number:',
    },

    actions: {
      edit: {
        tooltip: 'edit details',
      },
    },
  },

  notifications: {
    title: 'Notifications',

    actions: {
      edit: {
        tooltip: 'edit notifications',
      },
    },
  },

  form: {
    editUserDetails: {
      title: 'edit details {{name}}',

      fields: {
        full_name: {
          display: 'full name',
          placeholder: 'Enter full name',
        },

        email: {
          display: 'Email',
        },

        timezone: {
          display: 'timezone',
          info: 'You will get your notifications according to specified time zone. The time zone is updated according to the address you insert',
        },

        phone_num: {
          display: 'Phone',
          placeholder: 'Add phone',
        },
      },

      footer: {
        confirm: 'save',
      },

      confirmationModal: {
        header: 'heads up: you are about to change account details',
        body: 'You are about to change account details with the following values: </br>name: <strong>{{name}}</strong></br>phone: <strong>{{phone}}</strong></br>timezone: <strong>{{timezone}}</strong></br></br><strong>Are you sure you want to continue?</strong>',
        confirmText: 'continue',
        deleteText: 'cancel',

        errorToast: {
          message: 'could not update details',
        },
      },
    },

    editUserNotifications: {
      title: 'edit notifications {{name}}',

      footer: {
        confirm: 'save',
      },

      confirmationModal: {
        header: 'heads up',
        body: 'You are about to change notifications settings</br><strong>Are you sure you want to continue?</strong>',
        confirmText: 'continue',
        deleteText: 'cancel',

        errorToast: {
          message: 'could not update notifications settings',
        },

        successToast: {
          message: 'updated notifications successfully',
        },
      },
    },
  },
};

export default accountSettings;
