import { IOrg } from 'models/org';

export enum OrgsActions {
  SetVieworg = '[orgs] Set View org',
  SetOrgs = '[orgs] Set orgs',
}

export type SetVieworg = {
  type: OrgsActions.SetVieworg;
  payload: IOrg | null;
};

export type SetOrgs = {
  type: OrgsActions.SetOrgs;
  payload: IOrg[];
};

// Action creators

export const setVieworg = (vieworg: IOrg | null): SetVieworg => {
  return {
    type: OrgsActions.SetVieworg,
    payload: vieworg,
  };
};

export const setOrgs = (orgs: IOrg[]): SetOrgs => {
  return {
    type: OrgsActions.SetOrgs,
    payload: orgs,
  };
};

export type OrgTypes = SetVieworg | SetOrgs;
