import { IOrg } from 'models/org';
import * as actions from '../actions/orgs';

const initialState: State = {
  viewOrg: null,
  orgs: [],
};

export const reducer = (state: State = initialState, action: actions.OrgTypes): State => {
  switch (action.type) {
    case actions.OrgsActions.SetVieworg:
      return { ...state, viewOrg: action.payload };
    case actions.OrgsActions.SetOrgs:
      return { ...state, orgs: action.payload };
    default:
      return state;
  }
};

export type State = {
  viewOrg: IOrg | null;
  orgs: IOrg[];
};
