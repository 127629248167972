import { IPermission } from 'models/permission';
import { User } from '../../models/user';
import * as actions from '../actions/auth';

const initialState: State = {
  user: null,
  token: null,
  permissions: [],
  redirect: null,
};

export const reducer = (state: State = initialState, action: actions.AuthTypes): State => {
  switch (action.type) {
    case actions.AuthActions.Logout:
      return { ...state, user: null };
    case actions.AuthActions.SetToken:
      return { ...state, token: action.payload.token };
    case actions.AuthActions.SetPermissions:
      return { ...state, permissions: action.payload.permissions };
    case actions.AuthActions.Login:
      return { ...state, user: action.payload.user };
    case actions.AuthActions.SetRedirect:
      return { ...state, redirect: action.payload };
    case actions.AuthActions.RemoveRedirect:
      return { ...state, redirect: null };
    default:
      return state;
  }
};

export type State = {
  user: User | null;
  token: string | null;
  permissions: IPermission[];
  redirect: { link: string; parameter: string; secondparameter: string; org: string } | null;
};
