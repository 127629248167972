const customerFile = {
  title: 'customer file',

  forms: {
    lastUpdate: 'last updated',
    reviewData: 'Please review your details',
    overMonth: `It's been over a month since your last review. Ensure all details are correct, then click “Approve” at the bottom of the section.`,
    noDataProvided: 'No data provided yet. Please fill out this section',
    dataHelpsUs: `This data helps us assess your breach readiness and provide accurate security recommendations.`,

    crownJewels: {
      addField: 'Add',
      title: 'Crown Jewels',
      subtitle: 'The list of the most important hosts in the organization which require special attention',
      approveDetails: 'Approve details',

      headers: {
        ip: 'IP Address',
        hostname: 'Host Name',
        notes: 'Notes',
      },

      fields: {
        machineAddress: {
          placeholder: 'Ex 192.166.1.1',
          validation: 'Please enter valid IP',
          duplicats: 'No duplicates are allowed',
        },

        machineHostName: {
          placeholder: 'Enter machine host name',
          validation: 'Text is not valid, please use 0-9 a-z A-Z _-.only',
          duplicats: 'No duplicates are allowed',
        },

        notes: {
          placeholder: 'Enter notes',
          validation: 'Text is not valid, please use english only',
        },
      },

      saveChanges: 'save',
      cancelChanges: 'cancel',

      noData: {
        title: 'No hosts yet',
        text: 'We need your company hosts to monitor and protect your online </br> assets, ensuring comprehensive security and breach readiness.',
        add: 'add host',
      },

      successToast: {
        message: 'updated crown jewels',
      },
    },

    domains: {
      addField: 'Add',
      title: 'Domains',
      subtitle: 'List of all of the organization public domains, including marketing websites and mail relays',
      saveChanges: 'save',
      cancelChanges: 'cancel',
      approveDetails: 'Approve details',

      headers: {
        domains: 'Domain',
        notes: 'Notes',
      },

      fields: {
        company_domain: {
          displayName: 'company domain',
          placeholder: 'Ex. profero.io',
          validation: 'Please enter valid domain',
          duplicats: 'No duplicates are allowed',
        },

        has_mx_record: {
          displayName: 'MX record',
        },

        notes: {
          displayName: 'notes',
          placeholder: 'Enter notes',
          validation: 'Text is not valid, please use english only',
        },
      },

      noData: {
        title: 'No domains yet',
        text: 'We need your company domains to monitor and protect your online </br> assets, ensuring comprehensive security and breach readiness.',
        add: 'add domain',
      },

      successToast: {
        message: 'updated domains',
      },
    },

    securityStackData: {
      title: 'Organization and Workforce Details',
      subtitle: 'Details about the organization, people and general information',
      approveDetails: 'Approve details',

      subjects: {
        riskAndRecovery: 'Risk & Recovery',
        securityAndTechnologies: 'Security Tools and Technologies',
        generalInformation: 'General Information',
        securityTeams: 'Security Teams',
        workforce: 'Workforce and IT Infrastructure',
      },

      fields: {
        additional_info: {
          displayName: 'Any other important information about the organization?',
          placeholder: 'Add additional information',
          viewOnly: 'Additional relevant information',
        },

        edr_xdr: {
          displayName: 'Select Endpoint Detection and Response (EDR) or Extended Detection and Response (XDR)',
          placeholder: 'Add edr xdr',
          viewOnly: 'Endpoint Detection and Response (EDR) or Extended Detection and Response (XDR) in use',
          addOtherPlaceholder: 'Add an unlisted edr xdr',
          addOtherPValidation: 'Text is not valid, please use english only',
        },

        email_security: {
          displayName: 'Select Email Security Solutions',
          placeholder: 'Add email security',
          viewOnly: 'Email Security Solutions in use',
          addOtherPlaceholder: 'Add an unlisted email security',
          addOtherPValidation: 'Text is not valid, please use english only',
        },

        endpoint_management: {
          displayName: 'Select Endpoint Management Systems',
          placeholder: 'Add endpoint management',
          viewOnly: 'Endpoint Management Systems in use',
          addOtherPlaceholder: 'Add an unlisted endpoint management',
          addOtherPValidation: 'Text is not valid, please use english only',
        },

        firewall: {
          displayName: 'Select Web Firewall',
          placeholder: 'Add firewall',
          viewOnly: 'Web Firewall in use',
          addOtherPlaceholder: 'Add an unlisted firewall',
          addOtherPValidation: 'Text is not valid, please use english only',
        },

        iam: {
          displayName: 'Select Identity and Access Management (IAM)',
          placeholder: 'Add iam',
          viewOnly: 'Identity and Access Management (IAM) in use',
          addOtherPlaceholder: 'Add an unlisted iam',
          addOtherPValidation: 'Text is not valid, please use english only',
        },

        ips_idps: {
          displayName: 'Select Intrusion Prevention Systems (IPS) and Intrusion Detection and Prevention Systems (IDPS)',
          placeholder: 'Add ips idps',
          viewOnly: 'Intrusion Prevention Systems (IPS) and Intrusion Detection and Prevention Systems (IDPS) in use',
          addOtherPlaceholder: 'Add an unlisted ips idps',
          addOtherPValidation: 'Text is not valid, please use english only',
        },

        mdm: {
          displayName: 'Select Mobile Device Management (MDM)',
          placeholder: 'Add mdm',
          viewOnly: 'Mobile Device Management (MDM) in use',
          addOtherPlaceholder: 'Add an unlisted mdm',
          addOtherPValidation: 'Text is not valid, please use english only',
        },

        most_common_os: {
          displayName: 'Which operating system is the most common in the organization?',
          placeholder: 'Add most common os',
          viewOnly: 'Most common operating system in use',
        },

        org_name: {
          displayName: 'org_name',
          placeholder: 'Add org_name',
        },

        prospect_id: {
          displayName: 'prospect id',
          placeholder: 'Add prospect id',
        },

        web_app_firewall: {
          displayName: 'Select Web Application Firewall',
          placeholder: 'Add web app firewall',
          viewOnly: 'Web Application Firewall in use',
          addOtherPlaceholder: 'Add an unlisted web app firewall',
          addOtherPValidation: 'Text is not valid, please use english only',
        },

        allows_byod: {
          displayName: 'The organization allows "bring your own devices" (BYOD)',
        },

        allows_iot: {
          displayName: 'The organization allows IoT devices in the network',
        },

        has_on_prem_ad: {
          displayName: 'Active directory servers on-prem',
          viewOnly: 'On prem AD',
        },

        has_on_prem_exchange: {
          displayName: 'On-prem exchange servers',
          viewOnly: 'Prem exchange',
        },

        has_patch_management: {
          displayName: 'The organization has a patch management system',
        },

        has_recovery_backup: {
          displayName: 'The organization has a recovery and backup procedure and protocols',
        },

        is_soc_external_team: {
          displayName: 'Is SOC external/internal',
        },

        org_has_soc: {
          displayName: 'Org has SOC?',
        },

        uses_google_workspace: {
          displayName: 'Google Workspace',
          viewOnly: 'Google workspace',
        },

        have_hybrid_work: {
          displayName: 'The organization allows remote work for some or all employees',
          viewOnlyTitle: 'Remote work policies',
          viewOnly: 'The organization allows remote work for some or all employees',
        },

        uses_microsoft_365: {
          displayName: 'Microsoft 365',
          viewOnly: 'Microsoft 365',
        },

        num_of_endpoints: {
          displayName: 'How many endpoints (servers, PCs, laptops, etc.) are in the organization?',
          placeholder: 'Enter a number',
          viewOnly: 'Total number of endpoints (servers, PCs, laptops, etc.)',
        },

        cloud_services: {
          displayName: 'Does the organization use any cloud services? Which services?',
          placeholder: 'Select services',
          viewOnly: 'Cloud services currently in use',
          addOtherPlaceholder: 'Add an unlisted service',
          addOtherPValidation: 'Text is not valid, please use english only',
        },

        device_types: {
          displayName: 'Which type of IoT devices?',
          placeholder: 'Enter devices',
          viewOnly: 'Types of IoT devices',
        },

        security_department_size: {
          displayName: 'Does the organization have a dedicated security team, and what is its size?',
          placeholder: 'Enter a number',
          viewOnly: 'Dedicated security team size (if exists)',
        },

        num_of_employees: {
          displayName: 'How many employees work in your organization?',
          placeholder: 'Enter a number',
          viewOnly: 'Total number of employees',
        },

        max_down_time: {
          displayName: 'For how long can the business sustain a complete network shut down due to a cyber incident?',
          placeholder: 'Enter max down time',
          viewOnly: 'Maximum downtime the business can sustain during a network shutdown',
        },

        sector: {
          displayName: 'To which sector does the organization belong?',
          placeholder: 'Enter sector',
          viewOnly: 'Industry sector',
        },

        applies: {
          displayName: 'Select what applies',
          viewOnly: 'Current organizational policies',
        },

        orgsServices: {
          displayName: 'Does the organization use following services?',
          viewOnly: 'Other services in use',
        },

        soc_status: {
          displayName: 'Does your organization have a SOC? Is it an external/internal team?',
          viewOnly: 'SOC team presence and type',

          options: {
            internal: 'Internal',
            external: 'External',
            none: `Doesn't have a SOC`,
          },
        },

        addOther: {
          add: 'Add other',
          cancel: 'cancel',
          save: 'Add',
        },
      },

      successToast: {
        message: 'updated security stack',
      },

      footer: {
        confirm: 'save changes',
      },
    },

    systems: {
      title: 'Security Systems and Events',
      subtitle: 'Information regarding the organization security systems and vendors',
    },

    emergencyInfo: {
      updateInfo: {
        title: 'edit information',

        fields: {
          email: {
            display: 'Emergency email address',
            placeholder: 'Add email',
          },

          csm_email: {
            display: 'CSM email address',
            placeholder: 'Add email',
          },

          phone: {
            display: 'Emergency phone number',
            placeholder: 'Add phone',
          },
        },

        footer: {
          confirm: 'save changes',
          cancel: 'cancel',
        },

        successToast: {
          message: 'emergency info updated successfully',
        },
      },
    },

    contacts: {
      title: 'Emergency Contact Details',
      subtitle: 'List of contacts for use in emergencies',
      approveDetails: 'Approve details',

      contactList: {
        title: 'Customer Contact List',
        cancel: 'cancel',
        save: 'save',

        actions: {
          options: {
            add: 'add contact',
            edit: 'edit contacts list',
            export: 'export contacts list',
          },

          changeDetails: {
            successToast: {
              message: 'contacts updated successfully',
            },

            errorToast: {
              message: 'Could not update contacts',
            },
          },

          copyMessage: 'value copied successfully',
        },

        form: {
          create: {
            title: 'Create new contact',

            fields: {
              name: {
                display: 'Name',
                placeholder: 'Add name',
              },

              title: {
                display: 'Title',
                placeholder: 'Add title',
              },

              email: {
                display: 'Email',
                placeholder: 'Add email',
              },

              phone_num: {
                display: 'Phone',
                placeholder: 'Add phone',
              },
            },

            footer: {
              confirm: 'add contact',
            },

            successToast: {
              message: 'contact added',
            },

            errorToast: {
              message: 'Could not add contact',
            },
          },

          update: {
            title: 'edit contact {{name}}',

            fields: {
              name: {
                display: 'Name',
                placeholder: 'Add name',
              },

              title: {
                display: 'Title',
                placeholder: 'Add title',
              },

              email: {
                display: 'Email',
                placeholder: 'Add email',
              },

              phone_num: {
                display: 'Phone',
                placeholder: 'Add phone',
              },
            },

            footer: {
              confirm: 'save',
            },

            successToast: {
              message: 'contact updated successfuly',
            },

            errorToast: {
              message: 'Could not edit contact',
            },
          },
        },
      },

      reportIncident: {
        title: 'do you have any doubts?',
        report: 'open incident',
        activeIncident: 'there is an active incident',

        confirmModal: {
          incidentActive: 'There is {{count}} active incident. Continue with the new incident?',
          incidentsActive: 'There are {{count}} active incidents. Continue with the new incident?',

          footer: {
            cancel: 'cancel',
            confirm: 'continue',
          },
        },

        form: {
          title: 'new incident',

          fields: {
            notes: {
              display: 'additional notes* (mandatory field)',
              placeholder: 'Add additional notes',
            },

            summary: {
              display: 'incident summary* (mandatory field)',
              placeholder: 'Add a summary',
            },

            name: {
              display: 'name',
            },
          },

          footer: {
            confirm: 'submit',
          },

          successToast: {
            message: 'incident reported successfully',
          },

          errorToast: {
            message: 'could not report incident',
          },
        },
      },

      emergencyDetails: {
        title: 'Profero Contacts',
        customerSuccessManager: 'Customer Success Manager',
        customerSuccessManagerText: 'Your dedicated CSM is here to help. </br>Contact them anytime for support or guidance.',
        emailTitle: 'Maayan Raviv',
        emailText: 'maayan@profero.io',
        emergencyTitle: 'Emergency details',
        emergencyText: `Suspect a Security Issue? </br>Report it immediately by clicking the button below. Alternatively, </br>you can reach us via email, phone, or Slack for urgent assistance.`,
        openIncident: 'open incident',
        proferoEmail: 'profero.emergency@gmail.com',
        proferoPhone: '+972-846629566',
      },

      form: {
        create: {
          title: 'Create new contact',

          fields: {
            name: {
              display: 'Name',
              placeholder: 'Add name',
            },

            title: {
              display: 'Title',
              placeholder: 'Add title',
            },

            email: {
              display: 'Email',
              placeholder: 'Add email',
            },

            phone_num: {
              display: 'Phone',
              placeholder: 'Add phone',
            },
          },

          footer: {
            confirm: 'add contact',
          },

          successToast: {
            message: 'contact added',
          },

          errorToast: {
            message: 'Could not add contact',
          },
        },

        update: {
          title: 'edit contact {{name}}',

          fields: {
            name: {
              display: 'Name',
              placeholder: 'Add name',
            },

            title: {
              display: 'Title',
              placeholder: 'Add title',
            },

            email: {
              display: 'Email',
              placeholder: 'Add email',
            },

            phone_num: {
              display: 'Phone',
              placeholder: 'Add phone',
            },
          },

          footer: {
            confirm: 'save',
          },

          successToast: {
            message: 'contact updated successfuly',
          },

          errorToast: {
            message: 'Could not edit contact',
          },
        },
      },
    },

    subscription: {
      title: 'Subscription',
      subtitle: 'Details regarding the current agreement',

      fieldsSubject: {
        responseTime: {
          title: 'Response time',
          subtitle: 'SLA to get a response from a team member',
        },

        incidentsDays: {
          title: 'Incident days',
          subtitle: 'Number of incident days per agreement',
        },

        investigators: {
          title: 'Investigators per day',
          subtitle: 'Maximum number of investigators per day',
        },

        endpoints: {
          title: 'Number of Endpoints',
          subtitle: 'Maximun number of concurrent online sensors',
        },

        subscription: {
          title: 'Subscription',
          subtitle: 'Summary of the customer agreement terms',
        },

        compromiseAssessments: {
          title: 'Compromise Assessments',
          subtitle: 'Which modules are included in the agreement, and their cadence',
        },
      },

      fields: {
        emergency: {
          display: 'Emergency (min)',
          placeholder: 'Enter emergency',
          viewOnly: 'Emergency:',
        },

        nonEmergency: {
          display: 'Non-emergency (hr)',
          placeholder: 'Enter non-emergency',
          viewOnly: 'Non-emergency:',
        },

        totalDays: {
          display: 'Total number',
          placeholder: 'Enter total number',
        },

        totalUsed: {
          display: 'Days used',
          placeholder: 'Enter days used',
        },

        investigators: {
          placeholder: 'Enter investigators',
        },

        endpoints: {
          placeholder: 'Enter endpoints',
        },

        subscriptionStartDate: {
          display: 'Current subscription start date',
          viewOnly: 'Current subscription period:',
        },

        subscriptionEndDate: {
          display: 'Current subscription end date',
        },

        serviceStartDate: {
          display: 'Service Start Date',
          viewOnly: 'Service Start Date:',
        },

        totalTime: {
          display: 'Total time with Profero',
          placeholder: 'Enter total time',
          viewOnly: 'Total time with Profero:',
        },

        modules: {
          module: {
            display: 'Module',
            placeholder: 'Enter module',
            validation: 'Text is not valid, please use english only',
            duplicats: 'No duplicates are allowed',
          },

          period: {
            display: 'Period',
            placeholder: 'Enter period',
            validation: 'Text is not valid, please use english only',
          },

          notes: {
            display: 'Notes',
            placeholder: 'Enter notes',
            validation: 'Text is not valid, please use english only',
          },
        },
      },

      successToast: {
        message: 'updated subscription',
      },

      actions: {
        deleteModule: {
          deleteModuleModal: {
            header: 'heads up',
            body: `you are abuot to delete the module: {{module}} </br>Are you sure you want to delete it?`,
            confirm: 'confirm',
            cancel: 'cancel',
          },
        },
      },

      save: 'save changes',
      saveItem: 'save',
      cancel: 'cancel',
      addField: 'Add',
      approveDetails: 'Approve details',
    },
  },
};

export default customerFile;
