const conditionalAccess = {
  title: 'conditional access',
  newRuleBtn: 'create ca',

  actions: {
    delete: {
      tooltip: 'delete conditional access',

      modal: {
        header: 'heads up: you are about to delete a conditional access',
        body: 'You are about to delete a conditional access that includes the rules: <strong>{{name}}</strong>.</br><strong>Are you sure you want to delete it?</strong>',
        confirmText: 'yes, delete it',
        deleteText: 'cancel',
      },

      successToast: {
        message: 'conditional access deleted',
      },

      errorToast: {
        message: 'Could not delete conditional access',
      },
    },

    edit: {
      tooltip: 'edit conditional access',
    },
  },

  card: {
    geo: 'geo',
    equals: 'equals',
    ipRange: 'ip range',
    and: 'and',
    fieldType: 'field type',
    value: 'value',

    expressionPreview: {
      displayName: 'expression preview',
      placeholder: 'Expression will be added automaticly',
    },
  },

  form: {
    create: {
      title: 'create conditional access',
      equals: 'equals',
      and: 'and',
      addField: 'add field',

      fields: {
        type: {
          display: 'field type',

          ruleTypeOptions: {
            geo: 'geo',
            ipRange: 'ip range',
          },
        },

        country: {
          display: 'value',
          placeholder: 'Select countries',
        },

        ip: {
          display: 'value',
          placeholder: 'Ex. __.__.__.__/__',
          validation: 'Please enter valid IP (range 16-32). Internal IP addresses are not allowed',
        },
      },

      confirmModal: {
        header: 'heads up: you are about to add a conditional access',
        body: 'You are about to add a conditional access that includes the rules: <strong>{{name}}</strong>.</br><strong>Are you sure you want to add it?</strong>',
        confirmText: 'yes, continue',
        cancel: 'cancel',

        successToast: {
          message: 'conditional access added successfully',
        },

        errorToast: {
          message: 'Could not add conditional access',
        },
      },

      footer: {
        expressionPreview: {
          display: 'expression preview',
          placeolder: 'Expression will be added automaticly',
        },

        confirm: 'save and deploy',
        cancel: 'Cancel',
      },
    },

    edit: {
      title: 'edit conditional access',
      equals: 'equals',
      and: 'and',
      addField: 'add field',

      fields: {
        type: {
          display: 'field type',

          ruleTypeOptions: {
            geo: 'geo',
            ipRange: 'ip range',
          },
        },

        country: {
          display: 'value',
          placeholder: 'Select countries',
        },

        ip: {
          display: 'value',
          placeholder: 'Ex. __.__.__.__/__',
          validation: 'Please enter valid IP (range 16-32). Internal IP addresses are not allowed',
        },
      },

      confirmModal: {
        header: 'heads up: you are about to edit a conditional access',
        body: 'You are about to edit a conditional access that includes the rules: <strong>{{name}}</strong>.</br><strong>Are you sure you want to edit it?</strong>',
        confirmText: 'yes, continue',
        cancel: 'cancel',

        successToast: {
          message: 'conditional access edited successfully',
        },

        errorToast: {
          message: 'Could not edit conditional access',
        },
      },

      footer: {
        expressionPreview: {
          display: 'expression preview',
          placeolder: 'Expression will be added automaticly',
        },

        confirm: 'save and deploy',
        cancel: 'Cancel',
      },
    },
  },
};

export default conditionalAccess;
