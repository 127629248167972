export enum LayoutFlagsActions {
  SetSensorsHistoryFlag = '[layoutFlags] Set layout flags',
  SetRedirectFlag = '[layoutFlags] Set redirect flags',
}

export type SetSensorsHistoryFlag = {
  type: LayoutFlagsActions.SetSensorsHistoryFlag;
  payload: boolean;
};

export type SetRedirectFlag = {
  type: LayoutFlagsActions.SetRedirectFlag;
  payload: boolean;
};

// Action creators

export const setSensorsHistoryFlag = (sensors_history_flag: boolean): SetSensorsHistoryFlag => {
  return {
    type: LayoutFlagsActions.SetSensorsHistoryFlag,
    payload: sensors_history_flag,
  };
};

export const setRedirectFlag = (redirect_flag: boolean): SetRedirectFlag => {
  return {
    type: LayoutFlagsActions.SetRedirectFlag,
    payload: redirect_flag,
  };
};

export type LayoutFlagsTypes = SetSensorsHistoryFlag | SetRedirectFlag;
