const layout = {
  sideBar: {
    userActions: {
      settings: 'Account Settings',
      logout: 'Logout',
    },

    orgsActions: {
      settings: 'organization settings',
      switchOrg: 'switch organization',
      searchOrgPlaceholder: 'search org',
    },

    pageSubject: {
      home: 'home',
      fileSharing: 'file sharing',
      configuration: 'configurations',
      sensorsInventory: 'sensors inventory',
      threatHunting: 'threat hunting',
      incidents: 'incidents',
      settings: 'settings',
      readiness: 'readiness',
    },

    pages: {
      permissions: 'permissions',
      organizations: 'organizations',
      users: 'users management',
      secrets: 'secrets',
      resources: 'resources',
      integrations: 'integrations',
      incidentHistory: 'history',
      incidentActive: 'active',
      threatIntelSearch: 'threat intel search',
      iocLookup: 'ioc lookup',
      irAnalysis: 'ir behavioural analysis',
      sensorsDashboard: 'sensors dashboard',
      reports: 'reports',
      feeds: 'news feeds',
      dashboard: 'dashboard',
      summary: 'summary',
      welcome: 'status',
      adminSettings: 'admin settings',
      prospectsManagement: 'prospect management',
      conditionalAccess: 'conditional access',
      cve: 'CVE-2024-3094',
      warp: 'warp status',
      upload: 'warp usage',
      auditOrg: 'audit org',
      auditSelf: 'audit',
      findings: 'findings',
      diagnostics: 'diagnostics',
      usage: 'usage',
      customerFile: 'customer file',

      labels: {
        beta: 'beta',
        new: 'new',
      },
    },

    newIncident: {
      title: 'do you have doubts?',
      openIncident: 'open incident',
    },

    switchOrgModal: {
      header: 'SOMTHING WENT WRONG',
      body: 'the chosen organization has no permissions, it is not possible to switch to that organization',
    },

    switchOrgConditionalAccessModal: {
      header: 'ACCESS DENIED',
      body: 'Please contact your representative in Profero for further assistance',
    },

    switchOrgWithRedirectConditionalAccessModal: {
      header: 'ACCESS DENIED',
      body: `this redirect link is under organization which you don't have access to`,
    },
  },

  header: {
    activeIncidents: 'ACTIVE INCIDENTS',
  },
};

export default layout;
