import modal from './modal';
import select from './select';
import searchInput from './searchInput';
import form from './form';
import datePicker from './datePicker';
import fileInput from './fileInput';

const views = {
  modal,
  select,
  searchInput,
  form,
  datePicker,
  fileInput,
};

export default views;
