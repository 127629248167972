const registrationTOTP = {
  welcome: 'welcome to rapid-ir',
  subtitle: 'profero',

  form: {
    loginBtn: 'verify',
    correct: 'Code verified!',
    loging: 'Loging into your account',
    error: 'Incorrect MFA code',
  },

  footer: {
    troubleText: 'having trouble to sign up?',
    troubleBtnText: 'click here',
  },

  loginTroubleModal: {
    header: 'having any troubles?',
    body: 'For any assistance please contact your Customer Success Manager in the relevant communication channel. if this is an emergency, please call the emergency hotline provided to you by the Profero team.',
  },

  exeededAttempts: {
    title: 'Unfortunately',
    sessionTerminate: 'This session is terminated,',
    body: 'due to too many attempts to enter wrong code</br>Please contact your representative in</br><strong>Profero</strong> for further assistance.',
  },

  viewManualCode: {
    header: 'totp manual conecting code',
    body: 'This is the manual code to connect the authenticator app to RAPID-IR:',
    copy: 'value copied successfully',
  },

  invitationError: {
    title: 'Somthing went wrong',
    body: 'Please contact your customer support',
  },

  userCreated: {
    title: 'Thank you',
    text1: 'we got your details and your request is',
    text2: 'being processed and pending approval.',
    text3: 'We will contact shortly via email',
  },

  steps: {
    one: {
      number: '1.',
      description: 'MFA code',
    },

    two: {
      number: '2.',
      description: 'User Credentials',
    },

    three: {
      number: '3.',
      description: 'TOTP',
    },

    four: {
      number: '4.',
      description: 'Pending Approval',
    },
  },

  installSteps: {
    title: 'Set Up Authenticator App',
    step1line1: '1. Install an authenticator app from',
    step1line2: 'your phone’s such as Authy, Microsoft Authenticator etc.',
    step2line1: '2. Add your account.',
    step2line2: 'a. In most apps, you can do this by tapping the + icon.',
    step3line2: `b. Scan the QR code by using your device's camera.`,
    cantSeeQR: '*Can’t scan the QR?',
    viewManualCode: 'View manual code',
    verify: '3.Verify your account',
    enterCode: 'Enter the 6 digits code provided for <strong>Rapid IR User</strong> from your authenticator app.',
  },
};

export default registrationTOTP;
