import { SessionStorage } from './enums/storage';

const load = async <T>(key: SessionStorage): Promise<T> => {
  let data: unknown;
  const item: string | null = sessionStorage.getItem(key);

  try {
    data = !item ? item : JSON.parse(item);
  } catch (error) {
    data = item;
  }

  return (await data) as T;
};

const sess = { load };

export default sess;
