import { call, CallEffect, delay, ForkEffect, put, race, take, TakeEffect, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import moment from 'moment-timezone';

import * as authActions from '../actions/auth';
import * as orgsActions from '../actions/orgs';
import * as featureFlagsActions from '../actions/feature-flags';
import * as uiActions from '../actions/ui';
import * as incidentsActions from '../actions/incidents';
import sess from '../../utils/session-storage';
import { SessionStorage } from '../../utils/enums/storage';
import { IFeatureFlagResponse, IGetIncidentsActiveResponse, ILoginAccessTokenResponse, ILoginOrgsListResponse } from 'models/response';
import { IUser, User } from 'models/user';
import { IOrg } from 'models/org';
import { serverAxios } from 'utils/http';
import { IFlag } from 'models/flag';
import { IPermission } from 'models/permission';
import { Permissions } from 'models/permissions';
import { errorCodeMessage } from 'utils/helpers';
import { SECOND_IN_MILLISECOND, TWO_MINUTES_IN_MILLISECOND } from 'utils/constants';

// ======================= Workers (Effects) =======================

// Login

function* loginSaga() {
  let loggedIn: boolean = yield true;

  while (loggedIn) {
    // Time for JWT exp in minutes
    // We turn the minutes into seconds, because the delayTime should be in seconds
    const delayTime = Number(sessionStorage.getItem(SessionStorage.Exp)!) * 60;

    // This code uses the race effect from Redux-Saga to run two asynchronous effects simultaneously 
    // and return the result of the one that completes first.
    // The JWT should be 15 minutes. we decrese in 2 minutes. so each ~13 minutes the we will get new token
    const [delayAction, logoutAction]: [CallEffect, TakeEffect] = yield race<CallEffect | TakeEffect>([
      // We need the delay time in millisecond
      // We take the delay time (15 minutes of the JWT), multiply by 1000 (second in milliseconds), 
      // minus 2 minutes - 120000 in milliseconds
      delay(delayTime * SECOND_IN_MILLISECOND - TWO_MINUTES_IN_MILLISECOND),
      take(authActions.AuthActions.Logout),
    ]);

    // If delayAction is truthy, it means that the delay timer expired, so the session is about to expire. 
    // This initiates a token refresh process.
    if (delayAction) {
      try {
        const token = yield call(async () => {
          // First we will bring the refresh token from the session storage
          return sess.load<string>(SessionStorage.RefreshToken).then(async (refreshToken: string) => {
            // We will get the JWT that we currently have in the session storage in order to get the user id
            const currentUser = sessionStorage.getItem(SessionStorage.JWT) || '';
            const user: IUser = JSON.parse(window.atob(currentUser.split('.')[1]));

            return serverAxios
              // We will wxecute the access-token API in order to get a new JWT
              .get(`/access-token?oid=${user.oid}`, {
                headers: { Authorization: `Bearer ${refreshToken}` },
              })
              .then((tokenResponse: AxiosResponse<ILoginAccessTokenResponse>) => {
                const newJWT: string = tokenResponse.data.token;
                const newUser = JSON.parse(window.atob(newJWT.split('.')[1]));
                const userPermissions: IPermission[] = user.scope;
                const now: number = moment().unix();
                const exp: number = newUser.exp;
                const duration: moment.Duration = moment.duration(moment.unix(exp).diff(moment.unix(now)));
                const getMinutes: number = duration.asMinutes();

                return [newJWT, getMinutes.toString(), userPermissions];
              });
          });
        });

        sessionStorage.setItem(SessionStorage.Exp, token[1]);
        yield put(authActions.setToken(token[0]));
        yield put(authActions.setPermissions(token[2]));
        sessionStorage.setItem(SessionStorage.JWT, token[0]);
      } catch (error) {
        if (typeof error === 'object' && error && 'status' in error) {
          const errorCode: number = error['status'];

          const message: string = errorCodeMessage(errorCode);

          yield put(uiActions.showErrorToast({ message }));
          yield sessionStorage.clear();
        }

        loggedIn = yield false;
      }

      if (!loggedIn) {
        yield put(authActions.logout());
      }
    }

    if (logoutAction) {
      loggedIn = yield false;
    }
  }
}

// Logout
function* logoutSaga() {
  yield put(authActions.setToken(null));
  yield sessionStorage.clear();
}

// Auto Login

function* autoLoginSaga() {
  yield put(uiActions.showAutoLoginSpinner(true));

  try {
    const tokenAndUser = yield call(async () => {
      return sess.load<string>(SessionStorage.RefreshToken).then(async (refreshToken: string) => {
        const currentUser: string = sessionStorage.getItem(SessionStorage.JWT) || '';
        const user: IUser = JSON.parse(window.atob(currentUser.split('.')[1]));

        return serverAxios
          .get(`/access-token?oid=${user.oid}`, {
            headers: { Authorization: `Bearer ${refreshToken}` },
          })
          .then((tokenResponse: AxiosResponse<ILoginAccessTokenResponse>) => {
            const refreshedUser: IUser = JSON.parse(window.atob(tokenResponse.data.token.split('.')[1]));
            const userPermissions: IPermission[] = refreshedUser.scope;

            return [tokenResponse.data.token, refreshedUser, userPermissions];
          });
      });
    });

    const orgsData = yield call(async () => {
      const currentUser: string = sessionStorage.getItem(SessionStorage.JWT) || '';
      const user: IUser = JSON.parse(window.atob(currentUser.split('.')[1]));
      return serverAxios
        .get('/user-org', {
          headers: { Authorization: `Bearer ${currentUser}` },
        })
        .then((orgsResponse: AxiosResponse<ILoginOrgsListResponse>) => {
          const orgs: IOrg[] = orgsResponse.data.orgs || [];
          const default_org: IOrg = orgs.find((org: IOrg) => org.id === user.oid) || {
            id: '',
            name: '',
          };
          sessionStorage.setItem(SessionStorage.DefaultOrg, JSON.stringify(default_org));

          return [orgs, default_org];
        });
    });

    const featureflags = yield call(async () => {
      const currentUser = sessionStorage.getItem(SessionStorage.JWT) || '';
      const user: IUser = JSON.parse(window.atob(currentUser.split('.')[1]));
      return serverAxios
        .get('/org-feature-flags', {
          headers: { Authorization: `Bearer ${currentUser}` },
        })
        .then((featureFlagsResponse: AxiosResponse<IFeatureFlagResponse>) => {
          const featureFlags: IFlag[] = featureFlagsResponse.data.feature_flags;
          const now: number = moment().unix();
          const exp: number = user.exp;
          const duration: moment.Duration = moment.duration(moment.unix(exp).diff(moment.unix(now)));
          const getMinutes: number = duration.asMinutes();

          return [featureFlags, getMinutes.toString()];
        });
    });

    const incidentsCounter = yield call(async () => {
      const currentUser = sessionStorage.getItem(SessionStorage.JWT) || '';

      const incidentsActivePagePermission: string | undefined = tokenAndUser[2].find(
        (permission: IPermission) => permission.permission === Permissions.IncidentActiveList,
      )?.permission;

      if (incidentsActivePagePermission) {
        return serverAxios
          .get('/incident/open', {
            headers: { Authorization: `Bearer ${currentUser}` },
          })
          .then((incidentsResponse: AxiosResponse<IGetIncidentsActiveResponse>) => {
            const incidentsCounter: number = incidentsResponse.data.incidents.length;

            return [incidentsCounter];
          })
          .catch(() => {
            return [0];
          });
      } else {
        return [0];
      }
    });

    yield sessionStorage.setItem(SessionStorage.JWT, tokenAndUser[0]);
    yield put(incidentsActions.setIncidentsCount(incidentsCounter[0]));
    yield put(authActions.setToken(tokenAndUser[0]));
    yield put(authActions.setPermissions(tokenAndUser[2]));
    yield put(authActions.login(new User(tokenAndUser[1])));
    yield put(orgsActions.setOrgs(orgsData[0]));
    yield put(orgsActions.setVieworg(orgsData[1]));
    yield put(featureFlagsActions.setFeatureFlags(featureflags[0]));
    yield sessionStorage.setItem(SessionStorage.SwitchOrgRunning, 'false');

    sessionStorage.setItem(SessionStorage.Exp, featureflags[1]);
  } catch (error) {
    if (typeof error === 'object' && error && 'status' in error) {
      const errorCode: number = error['status'];

      const message: string = errorCodeMessage(errorCode);

      yield put(uiActions.showErrorToast({ message }));
      yield sessionStorage.clear();
    }
  }

  return yield put(uiActions.showAutoLoginSpinner(false));
}

// ======================= Watchers =======================

// watchAuth Saga: This function listens for every dispatched action matching the specified types 
// (e.g., Logout, AutoLogin, Login) from authActions.AuthActions. 
// For each of these actions, it triggers the respective worker saga.

// TakeEvery is a Redux-Saga effect that listens for all instances of a specific action type. 
// It spawns a new saga for each action, allowing multiple instances to run concurrently.
// Here, takeEvery will trigger logoutSaga every time a Logout action is dispatched, autoLoginSaga 
// for AutoLogin, and loginSaga for Login.

// For example when dispatch(authActions.autoLogin()) is called in a component, autoLoginSaga is triggered via watchAuth.
// The autoLoginSaga then performs the necessary steps (e.g., checking for a stored token) 
// to manage the user’s authentication state.
export function* watchAuth(): IterableIterator<ForkEffect> {
  yield takeEvery(authActions.AuthActions.Logout, logoutSaga);
  yield takeEvery(authActions.AuthActions.AutoLogin, autoLoginSaga);
  yield takeEvery(authActions.AuthActions.Login, loginSaga);
}
