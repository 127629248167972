import React from 'react';

import classes from './PToast.module.scss';
import { ToastType } from 'utils/enums/toast-types';

type Props = {
  message?: string;
  type?: ToastType;
  className?: string;
  proggressBarClassName?: string;
  defaultMesage: string;
};

const PToastView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div
      className={`${classes['container']} ${props.type === ToastType.Error ? classes['container--error'] : ''} ${
        props.className || ''
      }`.trim()}
    >
      <div className={classes['message']}>
        <span data-testid="pToasterMessage" >{props.message || props.defaultMesage}</span>
      </div>
      <div className={`${classes['toastProgressBar']} ${props.proggressBarClassName || ''}`.trim()}></div>
    </div>
  );
};

PToastView.displayName = 'PToastView';
PToastView.defaultProps = {};

export default PToastView;
