import { IFlag } from 'models/flag';
import * as actions from '../actions/feature-flags';

const initialState: State = {
  flags: [],
};

export const reducer = (state: State = initialState, action: actions.FeatureFlagsTypes): State => {
  switch (action.type) {
    case actions.FeatureFlagsActions.SetFeatureFlags:
      return { ...state, flags: action.payload };
    default:
      return state;
  }
};

export type State = {
  flags: IFlag[];
};
