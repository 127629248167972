const users = {
  title: 'users management',
  newUserSettingBtn: 'create user',
  addUserToOrg: 'add existing user',
  deleteUserFromOrg: 'remove user',

  generalFilter: {
    placeholder: 'Search for users',
  },

  actions: {
    decline: {
      confirmModal: {
        header: 'heads up',
        body: 'You are about to decline this invitation: </br>User name: <strong>{{name}}</strong></br>Email: <strong>{{email}}</strong></br><strong>Are you sure you want to decline invitation?</strong>',
        confirmText: 'yes, continue',
        deleteText: 'cancel',

        successToast: {
          message: 'invitation declined',
        },

        errorToast: {
          message: 'Could not decline invitation',
        },
      },
    },

    revoke: {
      confirmModal: {
        header: 'heads up',
        body: 'You are about to revoke this invitation: </br>User name: <strong>{{name}}</strong></br>Email: <strong>{{email}}</strong></br><strong>Are you sure you want to revoke invitation?</strong>',
        confirmText: 'yes, continue',
        deleteText: 'cancel',

        successToast: {
          message: 'user invitation was revoked',
        },

        errorToast: {
          message: 'could not revoke user',
        },
      },
    },

    resend: {
      confirmModal: {
        header: 'heads up',
        body: 'You are about to resend invitation with the following details: </br>User name: <strong>{{name}}</strong></br>Email: <strong>{{email}}</strong></br>Phone number: <strong>{{phone}}</strong></br>Organization: <strong>{{organization}}</strong></br>Visibility: <strong>{{visibility}}</strong>.</br><strong>Are you sure you want to resend the invitation?</strong>',
        confirmText: 'yes, send it',
        deleteText: 'cancel',

        successToast: {
          message: 'resend invite to {{name}}',
        },

        errorToast: {
          message: 'Could not send invitation',
        },
      },
    },

    review: {
      confirmModal: {
        header: 'heads up',
        body: 'You are about to approve and set a role with the following user: </br>User name: <strong>{{name}}</strong></br>Email: <strong>{{email}}</strong></br> <u>This will grant the user access to confidential and sensitive information.</u> </br><strong>Are you sure you want to continue?</strong>',
        confirmText: 'yes, continue',
        deleteText: 'cancel',

        successToast: {
          message: 'new active user added',
        },

        errorToast: {
          message: 'could not activate user',
        },
      },
    },

    update: {
      confirmModal: {
        header: 'heads up',
        body: 'You are about to edit the following user’s details and permissions: </br>User name: <strong>{{name}}</strong></br>Email: <strong>{{email}}</strong></br> <u>Those changes might give this user access to confidential and sensitive information</u> </br><strong>Are you sure you want to continue?</strong>',
        confirmText: 'yes, send it',
        deleteText: 'cancel',

        successToast: {
          message: 'new active user added',
        },

        errorToast: {
          message: 'could not activate user',
        },
      },
    },

    deactivate: {
      confirmModal: {
        header: 'heads up',
        body: 'You are about to deactivate this user: </br>User name: <strong>{{name}}</strong></br>Email: <strong>{{email}}</strong></br> <u>This will disable all of this user’s access and permits to the Rapid IR platform.</u> </br><strong>Are you sure you want to continue?</strong>',
        confirmText: 'approve',
        deleteText: 'cancel',

        successToast: {
          message: 'User was deactivated',
        },

        errorToast: {
          message: 'Could not deactivate user',
        },
      },
    },

    resetPassword: {
      confirmModal: {
        header: 'heads up',
        body: 'You are about to reset password for this user: </br>User name: <strong>{{name}}</strong></br>Email: <strong>{{email}}</strong></br><strong>Are you sure you want to continue?</strong>',
        confirmText: 'reset Password',
        deleteText: 'cancel',

        successToast: {
          message: 'reset password email sent',
        },

        errorToast: {
          message: 'reset password faild',
        },
      },
    },

    fullrecovery: {
      confirmModal: {
        header: 'heads up',
        body: 'You are about to make a full recoevry for this user: </br>User name: <strong>{{name}}</strong></br>Email: <strong>{{email}}</strong></br><strong>Are you sure you want to continue?</strong>',
        confirmText: 'full recovery',
        deleteText: 'cancel',

        successToast: {
          message: 'full recovery email sent',
        },

        errorToast: {
          message: 'full recovery faild',
        },
      },
    },

    reactivate: {
      confirmModal: {
        header: 'heads up',
        body: 'You are about to reactivate the following user: </br>User name: <strong>{{name}}</strong></br>Email: <strong>{{email}}</strong></br><strong>Are you sure you want to continue?</strong>',
        confirmText: 'yes, continue',
        deleteText: 'cancel',

        successToast: {
          message: 'user reactivated',
        },

        errorToast: {
          message: 'could not reactivate user',
        },
      },
    },
  },

  table: {
    paginationText: 'users',

    invitations: {
      headers: {
        hidden_user: 'visibility',
        full_name: 'name',
        phone_num: 'phone number',
        created_time: 'created time',
      },

      extraDataTable: {
        title: 'Activity History',

        headers: {
          full_name: 'name',
          created_time: 'created time',
          created_by: 'created by',
          phone_num: 'phone number',
          hidden_user: 'visibility',
        },

        actionsBtns: {
          revoke: 'revoke invitation',
          resend: 'resend invite',
        },
      },
    },

    pending: {
      headers: {
        full_name: 'name',
        phone_num: 'phone number',
        hidden_user: 'visibility',
        actions: 'requests actions',
      },

      actionsBtns: {
        decline: 'decline',
        review: 'review',
      },
    },

    active: {
      headers: {
        full_name: 'name',
        first_login: 'first login',
        last_login: 'last login',
      },

      moreInfo: {
        tooltip: 'more info',
      },

      editUser: {
        tooltip: 'edit user',
      },

      extraDataTable: {
        details: {
          visibility: 'visibility',
          phone: 'phone',
          org: 'org.',
          createdBy: 'invite by',
          createdAt: 'date',
          role: 'role',
          timezone: 'timezone',
          notifications: 'notifications',
        },

        actionsBtns: {
          deactivate: 'deactivate user',
          resetPassword: 'reset password',
          fullRecovery: 'full recovery',
        },
      },
    },

    deactivate: {
      headers: {
        full_name: 'name',
        first_login: 'first login',
        last_login: 'last login',
      },

      moreInfo: {
        tooltip: 'more info',
      },

      extraDataTable: {
        details: {
          visibility: 'visibility',
          phone: 'phone',
          org: 'org.',
          createdBy: 'invite by',
          createdAt: 'date',
        },

        actionsBtns: {
          activate: 'reactivate user',
        },
      },
    },
  },

  form: {
    create: {
      title: 'Invite New User',

      fields: {
        full_name: {
          display: 'Name',
          placeholder: 'Add full name',
        },

        email: {
          display: 'Email',
          placeholder: 'Add email',
        },

        phone_num: {
          display: 'Phone number',
          placeholder: 'Add phone',
        },

        org_id: {
          display: 'Organization',
        },

        hidden_user: {
          display: 'User Visibility',
        },
      },

      footer: {
        confirm: 'invite user',
      },

      confirmModal: {
        header: 'heads up',
        body: 'You are sending invitation with the following details: </br>User name: <strong>{{name}}</strong></br>Email: <strong>{{email}}</strong>.</br><strong>Are you sure you want to send this invitation?</strong>',
        confirmText: 'yes, send it',
        deleteText: 'cancel',

        successToast: {
          message: 'invite sent',
        },

        errorToast: {
          message: 'Could not send invitation',
        },
      },
    },

    addExistingUserToOrg: {
      title: 'add existing user to organization',

      fields: {
        email: {
          display: 'Email',
          placeholder: 'Add email',
        },

        orgs: {
          display: 'Add to orgs',
          placeholder: 'Select orgs',
        },
      },

      footer: {
        confirm: 'add user',
        cancel: 'Cancel',
      },

      confirmModal: {
        header: 'heads up',
        body: 'You are adding to the organization the user with the following details: </br>Email: <strong>{{email}}</strong></br>Role: <strong>{{role}}</strong></br><strong>Are you sure you want to continue?</strong>',
        confirmText: 'yes, add user',
        deleteText: 'cancel',

        successToast: {
          message: 'user added to org',
        },

        errorToast: {
          message: 'Could not add user to org',
          addRoleError: 'Could not add role to the user',
        },
      },

      allTokenFailed: 'All tokens failed',
      userNotExist: 'user does not exist',
      userAssociated: 'user is already associated with this organization',
      userAssociatedUpdatedModal:
        '<strong>please notice:</strong> user is already associated with this organization, role updated to <strong>{{role}}</strong>',
      userAssociatedModal: '<strong>please notice:</strong> user is already associated with this organization',

      userAssociatedWithAllorg: 'user is already associated with these organizations',
    },

    deleteUserFromOrg: {
      title: 'remove user from organization',

      fields: {
        email: {
          display: 'Email',
          placeholder: 'Add email',
        },

        orgs: {
          display: 'Add to orgs',
          placeholder: 'Select orgs',
        },
      },

      footer: {
        confirm: 'remove user',
        cancel: 'Cancel',
      },

      confirmModal: {
        header: 'heads up',
        body: 'You are about to remove from the organization the user with the following details: </br>Email: <strong>{{email}}</strong>.</br><strong>Are you sure you want to continue?</strong>',
        confirmText: 'yes, remove user',
        deleteText: 'cancel',

        successToast: {
          message: 'user removed from org',
        },

        errorToast: {
          message: 'Could not remove user from org',
          notAssociated: 'user is not associated with this org',
        },
      },

      allTokenFailed: 'All tokens failed',
    },

    review: {
      title: 'Set role to new user {{name}}',

      details: {
        full_name: 'Name',
        email: 'Email',
        org: 'Org.',
        phone: 'Phone',
        created_by: 'Invite By',
        created_time: 'Date',
      },

      fields: {
        role: 'role',
      },

      permissionsList: {
        title: 'permissions List',

        headers: {
          permission: 'Permission',
          description: 'Description',
        },
      },

      footer: {
        confirm: 'set role',
      },
    },

    update: {
      title: 'edit user details and role {{name}}',

      details: {
        created_by: 'Invite By',
        created_time: 'Date',
      },

      fields: {
        roleType: {
          title: 'permission template',

          options: {
            guest: 'GUEST',
            member: 'MEMBER',
            ir: 'IR',
            custom: 'CUSTOM',
          },
        },

        phone_num: {
          display: 'Phone number',
          placeholder: 'Add phone',
          validation: '*Invalid phone number. Please review and adjust accordingly',
        },

        full_name: {
          display: 'Name',
          placeholder: 'Add full name',
        },

        role: {
          display: 'Role',
          placeholder: 'Add role',
        },
      },

      permissionsList: {
        title: 'Permissions List',

        headers: {
          permission: 'Permission',
          description: 'Description',
        },
      },

      footer: {
        confirm: 'submit',
      },

      successToast: {
        message: 'Edit Successful',
      },

      errorToast: {
        message: 'Could not edit user',
      },
    },

    resend: {
      title: 'Resend invite to {{name}}',

      fields: {
        full_name: {
          display: 'Name',
          placeholder: 'Add full name',
        },

        email: {
          display: 'Email',
          placeholder: 'Add email',
        },

        phone_num: {
          display: 'Phone number',
          placeholder: 'Add phone',
          validation: '*Invalid phone number. Please review and adjust accordingly',
        },

        org_id: {
          display: 'Organization',
        },

        hidden_user: {
          display: 'User Visibility',
        },
      },

      footer: {
        confirm: 'resend invite',
      },
    },
  },

  informativeTickets: {
    pendingsTickets: {
      title: 'pending users [{{count}}]',
      subtitleUserWaiting: 'User waiting for aproval',
      subtitleUsersWaiting: 'Users waiting for approval',
      subtitleNoUsersWaiting: 'No users waiting for approval      ',

      card: {
        userWaiting: 'is waiting for approval',

        actionBtns: {
          decline: 'decline',
          review: 'review',
        },
      },
    },

    invitationsTickets: {
      title: 'invitations sent [{{count}}]',
      subtitle: 'User invitations expire after 24 hours',
      subtitleNoData: 'No invitations',

      card: {
        invitationSent: 'Invitation Sent {{date}}',

        invitationStatus: {
          expired: 'Invitation Expire',
          failed: 'User Failed to enroll',
        },

        actionBtns: {
          revoke: 'revoke',
          resend: 'resend',
        },
      },
    },
  },
};

export default users;
