const incidentsHistory = {
  title: 'history',

  table: {
    headers: {
      name: 'incident name',
      summary: 'summary',
      createdBy: 'created by',
      createdAt: 'created at',
      updatedAt: 'updated at',
      triggerChannel: 'trigger channel',
    },

    generalFilterText: 'Search in incidents',

    actions: {
      edit: {
        tooltipText: 'edit incident',
      },
    },

    extraData: {
      name: 'incident name',
      notes: 'additional notes',
      summary: 'incident summary',
    },
  },

  form: {
    edit: {
      title: 'edit incident {{name}}',

      fields: {
        name: {
          display: 'Incident Name',
          placeholder: 'Add incident name',
        },

        summary: {
          display: 'incident summary* (mandatory field)',
          placeholder: 'Add a summary',
        },

        notes: {
          display: 'additional notes',
          placeholder: 'Add additional notes',
        },

        trigger_channel: {
          display: 'Trigger channel',
          placeholder: 'Add channel',
        },

        incidentState: {
          display: 'State',
          placeholder: 'Add state',
        },

        valueType: {
          display: 'pick field type',
          options: {
            phone: 'phone',
            slack: 'slack',
            portal: 'portal',
          },
        },

        state: {
          display: 'pick state',
          options: {
            active: 'active',
            opened: 'opened',
            acknowledged: 'acknowledged',
            onhold: 'on hold',
            canceled: 'canceled',
            resolved: 'resolved',
          },
        },
      },

      footer: {
        confirm: 'Save',
        cancel: 'Cancel',
      },

      successToast: {
        message: 'Edit Successful',
      },

      errorToast: {
        message: 'Could not edit Incident',
      },
    },
  },
};

export default incidentsHistory;
