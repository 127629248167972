import * as actions from '../actions/sensors';

const initialState: State = {
  sensors_history_data: [],
};

export const reducer = (state: State = initialState, action: actions.SensorsTypes): State => {
  switch (action.type) {
    case actions.SensorsActions.SetSensorsHistoryData:
      return { ...state, sensors_history_data: action.payload };
    default:
      return state;
  }
};

export type State = {
  sensors_history_data: { name: string; value: string }[];
};
