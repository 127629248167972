/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import AppView from './App.view';

import * as uiActions from './store/actions/ui';
import * as authActions from './store/actions/auth';
import useRoutes, { PRoute } from './utils/hooks/use-routes';
import { serverAxios } from './utils/http';
import { AppState } from './models/app-store';
import { IAppRoute, AppRouteType } from './App.model';
import { GuardData } from './routes/guards/guard.model';
import { PToastProps } from 'views/PToast/PToast.model';
import { ErrorResponse, ILoginAccessTokenResponse } from 'models/response';
import { PAppModalProps } from 'views/PAppModal/PAppModal.model';
import { SessionStorage } from 'utils/enums/storage';
import { IUser } from 'models/user';
import { IPermission } from 'models/permission';
import { ResponseStatusCode } from 'utils/enums/http-response';
import { Navigate, Route } from 'react-router-dom';

const App: React.FC = () => {
  const { t } = useTranslation();

  const [showToastState, setShowToastState] = useState<boolean>(false);

  const dispatch = useDispatch();

  const routes: PRoute[] = useRoutes();
  const store: AppState = useSelector((state: AppState) => state);
  const isAuthenticated: boolean = useSelector((state: AppState) => !!state.auth.user);
  const loadingSpinner: boolean | number = useSelector((state: AppState) => state.ui.loadingSpinner);
  const autoLoginLoadingSpinner: boolean | number = useSelector((state: AppState) => state.ui.autoLoginLoadingSpinner);
  const token: string | null = useSelector((state: AppState) => state.auth.token);
  const toast: PToastProps | null = useSelector((state: AppState) => state.ui.toast);
  const appModal: PAppModalProps | null = useSelector((state: AppState) => state.ui.appModal);

  useEffect(() => {
    dispatch(authActions.autoLogin());
  }, [dispatch]);

  // Add authorization Bearer token to requests headers
  // each X time we get a new JWT - it will trigger this useEffect and will put the new JWT in our serverAxios (so all APIs will be with the updated JWT)
  useEffect(() => {
    if (token) {
      const tokenInterceptor: number = serverAxios.interceptors.request.use((request: AxiosRequestConfig) => {
        // We call access token each X minutes in order to get new JWT.
        // for this API call we don't want to add the Authorization: `Bearer ${token}` to the headers
        if (request.url?.includes('/access-token')) {
          return { ...request, headers: { ...request.headers } };
        }

        // When the JWT changes, we put it our serveraxios, so all APIS will be with the new JWT
        return { ...request, headers: { ...request.headers, Authorization: `Bearer ${token}` } };
      });

      return () => {
        serverAxios.interceptors.request.eject(tokenInterceptor);
      };
    }
  }, [token]);

  // This useEffect deals with a situation where a 401 error is returned from the server (invalid token or session).
  // In that case we would not want to exit the portal straight away, but try to get a new JWT.
  // If we fail to bring a new token - then we will exit the portal
  useEffect(() => {
    // We will check if a user is authenticated - if so, we continue
    if (isAuthenticated) {
      const logoutInterceptor: number = serverAxios.interceptors.response.use(
        (response: AxiosResponse): AxiosResponse => response,
        (error: ErrorResponse) => {
          // If we get 401 in the portal, we will try to get a new token. if we fail - we logout
          if (error.status === ResponseStatusCode.InvalidTokenOrSession) {
            const refreshToken: string | null = sessionStorage.getItem(SessionStorage.RefreshToken);
            const currentUser: string = sessionStorage.getItem(SessionStorage.JWT) || '';
            const user: IUser = JSON.parse(window.atob(currentUser.split('.')[1]));

            serverAxios
              .get(`/access-token?oid=${user.oid}`, {
                headers: { Authorization: `Bearer ${refreshToken}` },
              })
              .then((tokenResponse: AxiosResponse<ILoginAccessTokenResponse>) => {
                const newJWT: string = tokenResponse.data.token;
                const newUser: IUser = JSON.parse(window.atob(newJWT.split('.')[1]));
                const userPermissions: IPermission[] = user.scope;
                const now: number = moment().unix();
                const exp: number = newUser.exp;
                const duration: moment.Duration = moment.duration(moment.unix(exp).diff(moment.unix(now)));
                const getMinutes: number = duration.asMinutes();

                sessionStorage.setItem(SessionStorage.Exp, getMinutes.toString());
                dispatch(authActions.setToken(newJWT));
                dispatch(authActions.setPermissions(userPermissions));
                sessionStorage.setItem(SessionStorage.JWT, newJWT);
              })
              .catch(() => {
                dispatch(
                  uiActions.showErrorToast({
                    message: t('pages.common.invalidTokenOrSessionToast.message'),
                  }),
                );

                dispatch(authActions.logout());
              });
          }

          // All over the app, we check if HTTP response error !== 429
          // We must do this check, since we handle errors diffrenetly in the app
          // If it's 429 error, we have handle it here
          if (error.status === ResponseStatusCode.RateLimit) {
            dispatch(
              uiActions.showErrorToast({
                message: t('pages.common.tooManyRequests.message'),
              }),
            );
          }

          throw error;
        },
      );

      return () => {
        serverAxios.interceptors.response.eject(logoutInterceptor);
      };
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    setShowToastState(() => !!toast);
  }, [toast, setShowToastState]);

  const hideAppModal = () => {
    if (appModal?.onClose) {
      appModal?.onClose();
    }
    return dispatch(uiActions.hideAppModal());
  };

  // A function whose purpose is to map the routes and check if there is a fallback or not.
  const prepareRouteList: any = (routes: PRoute[]) => {
    const guardData: GuardData = {
      store: store,
    };

    return routes.reduce((acc: IAppRoute[], route: PRoute) => {
      if (route.guard && !route.guard.every((guard) => guard(guardData))) {
        if (route.fallback) {
          const contentRoute: IAppRoute = {
            type: AppRouteType.Fallback,
            path: route.path,
            to: route.fallback,
            children: route.children ? prepareRouteList(route.children) : null,
          };

          return [...acc, contentRoute];
        }

        return acc;
      }

      const component: React.FC | undefined = route['component'];
      const jsx: JSX.Element = route['jsx'];

      const contentRoute: IAppRoute = {
        type: AppRouteType.Page,
        path: route.path,
        component,
        jsx,
        children: route.children ? prepareRouteList(route.children) : null,
      };

      return [...acc, contentRoute];
    }, []);
  };

  // A function that returns the routes as jsx depending on the fallback.
  const generateRoutes = (routes: IAppRoute[]) => {
    return routes.map((route: IAppRoute) => {
      const Component: React.FC | undefined = route['component'];
      const jsx: JSX.Element | undefined = route['jsx'];

      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            route.type === AppRouteType.Fallback ? (
              <Navigate to={route.to}></Navigate>
            ) : (
              <React.Fragment>{Component ? <Component></Component> : jsx}</React.Fragment>
            )
          }
        >
          {route.children ? generateRoutes(route.children) : null}
        </Route>
      );
    });
  };

  return (
    <AppView
      loadingSpinner={loadingSpinner}
      autoLoginLoadingSpinner={autoLoginLoadingSpinner}
      toast={toast}
      showToast={showToastState}
      routesList={prepareRouteList(routes)}
      onHideToast={() => dispatch(uiActions.hideToast())}
      onToastShown={() => setShowToastState(() => false)}
      appModal={appModal}
      hideAppModal={hideAppModal}
      generateRoutes={generateRoutes}
    ></AppView>
  );
};

App.displayName = 'App';

export default App;
