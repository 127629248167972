const totp = {
  welcome: 'login to rapid ir',

  form: {
    title: 'Enter code from your mobile authenticator app.',

    validations: {
      otp: {
        required: 'Please enter valid 2FA code',
      },
    },

    loginBtn: 'verify',
    correct: 'Code verified!',
    loging: 'Loging into your account',
    error: 'Incorrect MFA code',
  },

  footer: {
    troubleText: 'having trouble to log in?',
    troubleBtnText: 'click here',
  },

  loginTroubleModal: {
    header: 'Trouble logging in?',
    body: 'If this is an emergency, please use one of the alternate emergency communication channels. If you have trouble logging in, please contact your customer support.',
  },

  exeededAttempts: {
    title: 'Unfortunately',
    sessionTerminate: 'This session is terminated,',
    body: 'due to too many attempts to enter wrong code</br>Please contact your representative in</br><strong>Profero</strong> for further assistance.',
  },

  invitationError: {
    title: 'Somthing went wrong',
    body: 'Please contact your customer support',
  },

  accessDenied: {
    title: 'Access denied',
    body: 'Please contact your representative in <strong>Profero</strong> for further assistance',
    errorCode: '(Error code 5004)',
    selectOrgPlaceholder: 'Select organization...',
  },

  accessDeniedSelectOrg: {
    title: 'Access denied to this organization',
    body: 'Please select another organization to login',
    selectOrgPlaceholder: 'Select organization...',
  },
};

export default totp;
