import axios, { AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';

import { ResponseStatusCode } from './enums/http-response';

const rootAxios = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/v1`,
  timeout: Number(process.env.REACT_APP_SERVER_REQUEST_TIMEOUT),
});

// Added axios retry for 429 error (too many requests)
axiosRetry(rootAxios, {
  retries: 4,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => error.response?.status === ResponseStatusCode.RateLimit,
});

rootAxios.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,

  (error) => {
    return Promise.reject({ message: error.response.data.message, status: error.response.status });
  },
);

export const serverAxios = rootAxios;
