import React from 'react';
import { useTranslation } from 'react-i18next';

import PToastView from './PToast.view';

import { PToastProps } from './PToast.model';
import { ToastType } from 'utils/enums/toast-types';

type Props = PToastProps;

const PToast: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <PToastView
      message={props.message}
      proggressBarClassName={props.proggressBarClassName}
      type={props.type}
      className={props.className}
      defaultMesage={props.type === ToastType.Error ? t('views.toast.message.error') : t('views.toast.message.success')}
    ></PToastView>
  );
};

PToast.displayName = 'PToast';
PToast.defaultProps = {};

export default PToast;
