export enum ResponseStatusCode {
  BadRequest = 400,
  InvalidTokenOrSession = 401,
  AccessDenied = 403,
  NotFound = 404,
  Conflict = 409,
  Gone = 410,
  RateLimit = 429,
  ServerError = 500,
}
