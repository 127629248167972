const sensors = {
  title: 'sensors dashboard',

  information: {
    onlineSensors: {
      title: '# of online sensors',
    },

    instalationKey: {
      title: 'sensor installation resources',
      key: 'Sensor Key:',
    },

    sensorsHistory: {
      title: 'sensors installation history',
      downloadCSV: 'download',
    },

    graph: {
      title: 'online sensors - last 7 days',
    },
  },

  table: {
    header: {
      searchSensors: 'Search sensors',

      searchSensorsBy: {
        title: 'By',
        ip: 'ip',
        hostname: 'Hostname',
      },

      sensorFieldPlaceholder: {
        searchByIP: 'Ex. 192.168.60.19',
        searchByHostname: 'Search by hostname',
      },

      searchBtn: 'search',
    },

    headers: {
      isOnline: 'online',
      hostname: 'host name',
      architecture: 'arch.',
      externalIp: 'external ip',
      internalIp: 'internal ip',
      aliveTimestamp: 'last seen',
      enrollTimestamp: 'first seen',
      macAddr: 'mac address',
      platform: 'os',
      is_isolated: 'isolated',
    },

    cells: {
      isIsolated: {
        isolated: 'isolated',
        offlineIsolated: 'offline isolated',
        notIsolated: 'not isolated',
      },
    },

    noResults: {
      initial: 'Search sensors according to IP or host name',
      noResultsTitle: 'no sensors found',
      noResutls: `We were unable to find any sensors that match the search parameters.</br>Please modify your search and try again.`,
      noOnlineSensors: 'No Online Sensors',
    },
  },
};

export default sensors;
