import { IPermission } from 'models/permission';
import { User } from '../../models/user';

export enum AuthActions {
  Logout = '[Auth] Logout',
  Login = '[Auth] Login',
  AutoLogin = '[Auth] Auto Login',
  SetToken = '[Auth] Set Token',
  SetPermissions = '[Auth] Set Permissions',
  SetRedirect = '[Auth] Set Redirect',
  RemoveRedirect = '[Auth] Remove Redirect',
}

export type Logout = {
  type: AuthActions.Logout;
};

export type SetToken = {
  type: AuthActions.SetToken;
  payload: { token: string | null };
};

export type SetPermissions = {
  type: AuthActions.SetPermissions;
  payload: { permissions: IPermission[] };
};

export type Login = {
  type: AuthActions.Login;
  payload: { user: User };
};

export type AutoLogin = {
  type: AuthActions.AutoLogin;
};

export type SetRedirect = {
  type: AuthActions.SetRedirect;
  payload: { link: string; parameter: string; secondparameter: string; org: string };
};

export type RemoveRedirect = {
  type: AuthActions.RemoveRedirect;
};

// Action creators

export const logout = (): Logout => {
  return {
    type: AuthActions.Logout,
  };
};

export const setToken = (token: string | null): SetToken => {
  return {
    type: AuthActions.SetToken,
    payload: { token },
  };
};

export const setPermissions = (permissions: IPermission[]): SetPermissions => {
  return {
    type: AuthActions.SetPermissions,
    payload: { permissions },
  };
};

export const login = (user: User): Login => {
  return {
    type: AuthActions.Login,
    payload: { user },
  };
};

export const autoLogin = (): AutoLogin => {
  return {
    type: AuthActions.AutoLogin,
  };
};

export const setRedirect = (redirect: { link: string; parameter: string; secondparameter: string; org: string }): SetRedirect => {
  return {
    type: AuthActions.SetRedirect,
    payload: redirect,
  };
};

export const removeRedirect = (): RemoveRedirect => {
  return {
    type: AuthActions.RemoveRedirect,
  };
};

export type AuthTypes = Logout | SetToken | Login | SetPermissions | SetRedirect | RemoveRedirect;
