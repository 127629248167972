const secrets = {
  title: 'secrets',
  newSecretBtn: 'Add Secret',
  noResults: 'No Results',

  header: {
    generalSearch: {
      placeholder: 'Search in secrets',
    },
  },

  form: {
    create: {
      title: 'new secret',

      fields: {
        bundle_name: {
          display: 'secret name',
          placeholder: 'Enter secret name',
        },

        bundle_type: {
          display: 'secret type',
          credentials: 'credentials',
          secureNote: 'secure note',
        },

        userName: {
          display: 'User name* (mandatory field)',
          placeholder: 'Enter user name',
        },

        password: {
          display: 'password* (mandatory field)',
          placeholder: 'Enter password',
        },

        website: {
          display: 'URL',
          placeholder: 'Enter URL',
        },

        otp: {
          display: 'set one-time password',
          placeholder: 'Enter otpauth',
        },

        generalKey: {
          placeholder: 'Enter label',
        },

        generalValue: {
          placeholder: 'Enter field content',
        },

        valueType: {
          display: 'pick field type',
          options: {
            text: 'text',
            password: 'password',
            otp: 'otp',
          },
        },

        dupliacateKeys: 'No keys duplicates are allowed, english language only',
      },

      addField: 'add field',

      footer: {
        confirm: 'add secret',
        cancel: 'cancel',
      },

      successToast: {
        message: 'new secret created',
      },

      errorToast: {
        message: 'Could not add Secret',
      },
    },

    edit: {
      title: 'edit {{name}}',
      noPermissionsReadValues: 'You have write-only permission. You can update values, but cannot view the existing ones.',

      fields: {
        bundle_name: {
          display: 'secret name',
        },

        bundle_type: {
          display: 'secret type',
        },

        generalKey: {
          placeholder: 'Enter label',
        },

        generalValue: {
          placeholder: 'Enter field content',
        },

        valueType: {
          display: 'pick field type',
          options: {
            text: 'text',
            password: 'password',
            otp: 'otp',
          },
        },

        dupliacateKeys: 'No keys duplicates are allowed, english language only',
      },

      addField: 'add field',

      footer: {
        confirm: 'save changes',
        cancel: 'cancel',
      },

      successToast: {
        message: 'changes saved to the {{type}}',
      },

      errorToast: {
        message: 'Could not edit secret',
      },
    },
  },

  dataCard: {
    actions: {
      delete: {
        tooltipText: 'delete secret',

        modal: {
          header: 'heads up: you are about to delete a secret',
          body: 'You are about to delete a report by the name of: <strong>{{name}}</strong>.</br><strong>Are you sure you want to delete it?</strong>',
          confirmText: 'yes, delete it',
          deleteText: 'cancel',
        },

        successToast: {
          message: 'Secret deleted successfully',
        },

        errorToast: {
          message: 'Could not delete Secret',
        },
      },

      edit: {
        tooltipText: 'edit secret',

        modal: {
          header: 'heads up: you are about to make irreversible changes',
          body: 'You are about to make changes to: <strong>{{name}}</strong>.</br><strong>Are you sure you want to change it?</strong>',
          confirmText: 'yes, change it',
          deleteText: 'cancel',
        },

        successToast: {
          message: 'Secret deleted successfully',
        },

        errorToast: {
          message: 'Could not delete Secret',
        },
      },

      copy: {
        successToast: {
          message: 'Secret coppied successfully',
        },

        errorToast: {
          message: 'Could not copy Secret',
        },
      },
    },

    footer: {
      dateCreated: 'date created {{value}}',
      dateModified: 'last modified {{value}}',
      createdBy: 'created by {{name}}',
      modifiedBy: 'modified by {{name}}',
    },
  },
};

export default secrets;
