import * as actions from '../actions/layout-flags';

const initialState: State = {
  sensors_history_flag: false,
  redirect_flag: false,
};

export const reducer = (state: State = initialState, action: actions.LayoutFlagsTypes): State => {
  switch (action.type) {
    case actions.LayoutFlagsActions.SetSensorsHistoryFlag:
      return { ...state, sensors_history_flag: action.payload };
    case actions.LayoutFlagsActions.SetRedirectFlag:
      return { ...state, redirect_flag: action.payload };
    default:
      return state;
  }
};

export type State = {
  sensors_history_flag: boolean;
  redirect_flag: boolean;
};
