const registrationOTP = {
  welcome: 'welcome to rapid-ir',
  subTitle: 'profero',

  form: {
    title: 'Please enter it here',
    phoneNumber: 'MFA code sent to {{userPhone}}',
    note: 'The code is valid for the next 5 minutes',

    validations: {
      otp: {
        required: 'Please enter valid 2FA code',
      },
    },

    loginBtn: 'verify',
    resendOTPText: 'didnt get the code?',
    resendOTP: 'resend',
    correct: 'Code verified!',
    loging: 'Loging into your account',
    error: 'Incorrect MFA code',
  },

  toast: {
    success: {
      message: 'OTP re-sent successfully !',
    },

    error: {
      message: 'Exceeded the maximum number of attempts',
    },
  },

  footer: {
    troubleText: 'having trouble to sign up?',
    troubleBtnText: 'click here',
  },

  loginTroubleModal: {
    header: 'having any troubles?',
    body: 'For any assistance please contact your Customer Success Manager in the relevant communication channel. if this is an emergency, please call the emergency hotline provided to you by the Profero team.',
  },

  linkExpired: {
    title: 'This link has expired,',
    text: 'Please contact your representative in</br><strong>Profero</strong> for further assistance.',
  },

  start: {
    text: 'The following identification process will have few steps to ensure the highest level of security',
    getMFA: 'Get your MFA code',
    button: 'send',
  },

  exeededAttempts: {
    title: 'Unfortunately',
    sessionTerminate: 'This session is terminated,',
    body: 'due to too many attempts to enter wrong code</br>Please contact your representative in</br><strong>Profero</strong> for further assistance.',
  },

  invitationError: {
    title: 'Somthing went wrong',
    body: 'Please contact your customer support',
  },

  steps: {
    one: {
      number: '1.',
      description: 'MFA code',
    },

    two: {
      number: '2.',
      description: 'User Credentials',
    },

    three: {
      number: '3.',
      description: 'TOTP',
    },

    four: {
      number: '4.',
      description: 'Pending Approval',
    },
  },
};

export default registrationOTP;
