const findings = {
  title: 'findings',
  newFindingBtn: 'create finding',

  form: {
    create: {
      title: 'add new finding',

      fields: {
        metadata_id: {
          display: 'metadata',
        },

        note: {
          display: 'note',
          placeholder: 'Please enter note',
        },

        system: {
          display: 'system',
        },

        lables: {
          display: 'acount',
        },
      },

      footer: {
        confirm: 'add finding',
      },

      successToast: {
        message: 'finding created successfully',
      },
    },

    update: {
      title: 'update finding',

      fields: {
        note: {
          display: 'note',
          placeholder: 'Please enter note',
        },


        lables: {
          display: 'acount',
        },
        
        status: {
          display: 'status',

          options: {
            new: 'NEW',
            regression: 'REGRESSION',
            toAddress: 'TO ADDRESS',
            acknowleged: 'ACKNOWLEDGED',
            riskAccepted: 'RISK ACCEPTED',
            completed: 'COMPLETED',
            reolved: 'RESOLVED',
          },
        },
      },

      footer: {
        confirm: 'save',
      },

      successToast: {
        message: 'finding updated successfully',
      },
    },
  },

  table: {
    moreInfo: 'more info',
    generalFilterText: 'Search in findings',

    actions: {
      delete: {
        modal: {
          header: 'heads up',
          body: 'You are about to delete a finding.</br><strong>Are you sure you want to delete it?</strong>',
          confirmText: 'continue',
          cancelText: 'cancel',
        },

        successToast: {
          message: 'finding deleted successfully',
        },
      },

      cancelDeletion: {
        modal: {
          header: 'heads up',
          body: 'You are about to cancel deletion of a finding.</br><strong>Are you sure you want to delete it?</strong>',
          confirmText: 'continue',
          cancelText: 'cancel',
        },

        successToast: {
          message: 'finding deletion canceled successfully',
        },
      },
    },

    extraData: {
      description: 'description',
      history: 'history',
      actions: 'actions to resolve',
      businessImpact: 'Business impact',
      readmore: 'read more',
      edit: 'edit finding',
      delete: 'delete finding',
      undoDeletion: 'cancel deletion',

      moreData: {
        impact: 'impact:',
        summary: 'summary:',
        status: 'status:',
        description: 'description',
        business: 'business impact',
        actions: 'actions to resolve',
        history: 'history',
        notes: 'notes',
        exit: 'Exit',
        accountLabel: 'account:',
        category: 'category:',

        headers: {
          date: 'date',
          user: 'user',
          details: 'details',
        },
      },
    },

    headers: {
      timestamp: 'date & time',
      system_name: 'system',
      updatedAt: 'last update',
    },
  },

  health: {
    search: 'Search in systems',
    noResults: 'No Results',

    filter: {
      status: 'status',
      selectAll: 'select all',
      clearAll: 'clear all',
    },
  },

  dashboard: {
    top3findings: 'Top Findings',

    scoreGraph: {
      title: 'Score',
      currentScore: 'Current score',
      comparison: 'Comparison Trend',
      last30: 'Last 30 days',
      last3: 'Last 3 Months',
      last12: 'Last 12 Months',
    },

    readinessGraph: {
      title: 'Readiness',
    },

    findingCard: {
      impact: 'Impact',
      summary: 'Summary:',
      status: 'Status:',
      desc: 'Description',
      history: 'History',
      actions: 'Actions',
      businessImpact: 'Business Impact',
      readmore: 'read more',

      moreData: {
        impact: 'impact:',
        summary: 'summary:',
        status: 'status:',
        description: 'description',
        business: 'business impact',
        actions: 'actions to resolve',
        history: 'history',
        notes: 'notes',
        exit: 'Exit',
        accountLabel: 'account:',
        category: 'category:',

        headers: {
          date: 'date',
          user: 'user',
          details: 'details',
        },
      },
    },

    extraInfo: {
      avgTime: 'Avg. Mean Time To Resolution',
      time: 'hh:mm',
      health: 'Connectors Health',
      status: 'Healty',
    },
  },
};

export default findings;
