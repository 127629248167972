const prospect = {
  login: 'login to questionnaire',
  welcome: 'Welcome',

  questionnaire: {
    tabs: {
      organizationDetails: 'organization details',
      organizationEnvironment: 'organization environment and assets',
      existingSecurity: {
        tabName: 'existing security measures',
        explanation: 'Please mark relevant used security systems',
      },
    },

    submitModal: {
      header: 'please answer required questions',
      text: 'It seems that not all mandatory questions have been answered. </br>Please make sure to respond to all required questions before submitting. </br>Thank you!',
    },

    submitModalConfirmation: {
      header: 'HEADS UP!',
      text: 'Before you proceed, please be aware that once you submit this questionnaire, you will not be able to make any changes to your responses.</br><strong>Do you want to continue?</strong>',
      confirm: 'continue',
      cancel: 'back',
    },

    completed: {
      title: 'Thank you!',
      text1: 'We’re good to go now.',
      text2: 'If there’s anything we’ve missed or anything else',
      text3: 'you’re willing to share with us, please let us know.',
      contactEmail: 'contact@profero.io',
    },

    expiredModal: {
      warningHeader: 'session expiring soon',
      header: 'session expired',
      body: 'Your session has expired. </br> Please log in again to continue answering the questionnaire.',
      warningBody:
        'Your session is about to expire in <strong>{{seconds}}</strong> seconds. </br>Please log in again to continue answering the questionnaire.',
      logout: 'Login',
    },

    footer: {
      back: 'back',
      next: 'next',
      finish: 'submit',
    },
  },

  form: {
    title: 'Please enter it here',
    phoneNumber: 'MFA code sent to {{userEmail}}',
    loginBtn: 'verify',
    resendOTPText: 'didnt get the code?',
    resendOTP: 'resend',
    correct: 'Code verified!',
    loging: 'Loging into your account',
    error: 'Incorrect MFA code',

    tosModal: {
      header: 'TOS is required',

      form: {
        intro: {
          text1: `As a boutique cybersecurity services company- Profero creates custom 
          solutions to complex cyber problems. The team specializes in resolving 
          critical cyber incidents. Leveraging our systematic approach, wide-ranging 
          expertise, and bespoke technology, we aim to help our customers solve their
          problems and create resilient systems and practices to ensure long-lasting 
          security for their products and applications`,
          text2: `We provide steady and efficient guidance throughout a crisis—from initial 
          response through to the recovery stages—and seek to work collaboratively 
          with you to develop solutions to mitigate potential future security breaches.`,
          text3: `Please fill the following questionnaire carefully, in order to help us tailor our 
          services to meet the particularities of your needs, challenges, and security 
          gaps.`,
        },

        fields: {
          termsOfService: {
            text: 'We appreciate your request to receive a quotation for the Profero services. Any information shared by you in connection with this form shall be regarded by Profero as confidential information of your organization and be used solely for the purpose of providing the quotation and facilitating the Profero services in connection thereto.',
            tos: 'Terms of Use',
          },
        },

        submit: 'lets start',
      },
    },
  },

  footer: {
    troubleText: 'having trouble to reset password?',
    troubleBtnText: 'click here',
  },

  loginTroubleModal: {
    header: 'Trouble logging in?',
    body: 'If this is an emergency, please use one of the alternate emergency communication channels. If you have trouble logging in, please contact your customer support.',
  },

  linkExpired: {
    title: 'This link has expired,',
    text: 'Please contact your representative in</br><strong>Profero</strong> for further assistance.',
  },

  exeededAttempts: {
    title: 'Unfortunately',
    sessionTerminate: 'This session is terminated,',
    body: 'due to too many attempts to enter wrong code</br>Please contact your representative in</br><strong>Profero</strong> for further assistance.',
  },

  invitationError: {
    title: 'Somthing went wrong',
    body: 'Please contact your customer support',
  },
};

export default prospect;
