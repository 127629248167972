const cve = {
  title: 'CVE-2024-3094',

  cards: {
    updated: 'Updated at',
  },

  table: {
    header: {
      searchSensors: 'Vulnerable Hosts',
    },

    headers: {
      isOnline: 'online',
      hostname: 'host name',
      architecture: 'arch.',
      externalIp: 'external ip',
      internalIp: 'internal ip',
      aliveTimestamp: 'last seen',
      enrollTimestamp: 'first seen',
      macAddr: 'mac address',
      platform: 'os',
      is_isolated: 'isolated',
    },

    cells: {
      isIsolated: {
        isolated: 'isolated',
        offlineIsolated: 'offline isolated',
        notIsolated: 'not isolated',
      },
    },

    noResults: {
      initial: 'Search sensors according to IP or host name',
      noResultsTitle: 'no sensors found',
      noResutls: `We were unable to find any sensors that match the search parameters.</br>Please modify your search and try again.`,
      noOnlineSensors: 'No Online Sensors',
    },
  },
};

export default cve;
