export enum Permissions {
  ReportList = 'portal-reports-service.list', // list reports, tags, get status
  ReportRead = 'portal-reports-service.read', // download report
  ReportUpdate = 'portal-reports-service.update', // edit report, set status
  ReportCreate = 'portal-reports-service.create', // add new report
  ReportDelete = 'portal-reports-service.delete', // delete report
  IncidentHistoryList = 'portal-home-service.incident.history.list', // list history incidents
  IncidentActiveList = 'portal-home-service.incident.active.list', // list active incidents
  IncidentUpdate = 'portal-home-service.incident.update', // edit incident
  IncidentCreate = 'portal-home-service.incident.create', // add new incident
  IncidentCreateFromPage = 'portal-home-service.incident.create.active', // add new incident from page
  SensorsRead = 'portal-sensor-lookup.read', // count, search, history, refresh, export sensors
  ResourcesList = 'portal-resources-service.list', // list resources, tags
  ResourcesRead = 'portal-resources-service.read', // download resource
  ResourcesUpdate = 'portal-resources-service.update', // edit resource
  ResourcesCreate = 'portal-resources-service.create', // add new resource
  ResourcesDelete = 'portal-resources-service.delete', // delete resource
  SecretsList = 'portal-secret-service.list', // list secrets, list keys
  SecretsRead = 'portal-secret-service.read', // read secret
  SecretsUpdate = 'portal-secret-service.update', // edit secret, delete key
  SecretsDelete = 'portal-secret-service.delete', // delete secret
  SecretsCreate = 'portal-secret-service.create', // add new secret
  UserInviteCreate = 'portal-user-mgmt.user.invite.create', // invite new user
  UserInviteUpdate = 'portal-user-mgmt.user.invite.update', // revoke invite, re-invite
  UsersList = 'portal-user-mgmt.user.list', // list users, list invitees
  UserApproveDecline = 'portal-user-mgmt.user.approve.update', //approve user, decline user, deactivate user
  UserAddRemoveOrg = 'portal-user-mgmt.user.org.update', //add or remove user to org
  UserUpdate = 'portal-user-mgmt.user.details.update', // update user details
  UserSelfDetailsRead = 'portal-user-mgmt.self.read', // read self details
  UserSelfDetailsUpdate = 'portal-user-mgmt.self.update', // update self details
  HomeIncidentsDaysRead = 'portal-home-service.incdient-days.read', // homme page incidents days read
  HomeIncidentsDaysUpdate = 'portal-home-service.incdient-days.update', // homme page incidents days update
  HomeContactsRead = 'portal-home-service.contact.read', // homme page read contacts
  HomeContactsEdit = 'portal-home-service.contact.update', // homme page edit contact list
  HomeContactsAdd = 'portal-home-service.contact.create', // homme page add new contact
  HomeContactsDelete = 'portal-home-service.contact.delete', // homme page delete a contact
  HomeEmergencyContactCreate = 'portal-home-service.emergency-contact.create', // homme page add new emergency contact info
  HomeEmergencyContactDelete = 'portal-home-service.emergency-contact.delete', // homme page remove item from emergency contact list
  HomeEmergencyContactRead = 'portal-home-service.emergency-contact.read', // homme page read emergency contact info
  HomeEmergencyContactUpdate = 'portal-home-service.emergency-contact.update', // homme page edit emergency contact info
  HomeEmergencyKeyCreate = 'portal-home-service.emergency-keys.create', // homme page add new emergency key info
  HomeEmergencyKeyDelete = 'portal-home-service.emergency-keys.delete', // homme page remove item from emergency key list
  HomeEmergencyKeyRead = 'portal-home-service.emergency-keys.read', // homme page read emergency key info
  HomeEmergencyKeyUpdate = 'portal-home-service.emergency-keys.update', // homme page edit emergency key info
  RolesList = 'portal-user-mgmt.role.list', // list all roles
  UserRoleUpdate = 'portal-user-mgmt.user.role.update', //change user's role
  UserRoleRead = 'portal-user-mgmt.user.role.read', // read the user's role
  OrgConditionalAccessUpdate = 'portal-user-mgmt.org.access.update', // add/edit conditional access restriction for org
  OrgConditionalAccessRead = 'portal-user-mgmt.org.access.read', // see conditional access restrictions for org
  AuditOrgRead = 'portal-audit-service.org.read', // get orgs audit list
  AuditSelfRead = 'portal-audit-service.self.read', // get self audit list
  FindingsRead = 'portal-findings-service.finding.read', // get data for a finding
  FindingCrate = 'portal-findings-service.finding.create', // creating finding
  FindingDelete = 'portal-findings-service.finding.delete', // delete finding
  FindingList = 'portal-findings-service.finding.list', // get all findings (and top 3)
  FindingUpdate = 'portal-findings-service.finding.update', // update finding
  FindingsMetaDataList = 'portal-user-mgmt.metadata.list', // get all metadata of findings
  FindingsReadiness = 'portal-findings-service.readiness.read', // get findings data analysis (charts)
  FindingsSystemsList = 'portal-home-service.system-details.list', // get systems list
  CustomerFileUpdate = 'portal-home-service.customer-file.update', // update crown-jewels, domains, security-stack
  CustomerFileDelete = 'portal-home-service.customer-file.delete', // delete crown-jewels, domains, security-stack
  CustomerFileRead = 'portal-home-service.customer-file.read', // read data in customer file page (crown-jewels, domains, security-stack, subscription)
  CustomerFileSubscriptionUpdate = 'portal-home-service.customer-file.subscription.update', // update subscription
  CustomerFileSubscriptionDelete = 'portal-home-service.customer-file.subscription.delete', // delete subscription
}
