const resetPassword = {
  welcome: 'reset password',

  form: {
    title: 'Create a new password',

    fields: {
      phone_num: {
        title: 'Phone',
        placeholder: 'Enter Phone Here',
      },

      password: {
        title: 'Password',
        placeholder: 'Create Password',
      },

      reenter_password: {
        title: 'Repeat Password',
        placeholder: 'Repeat Password as above',
        validation: 'confirm password not matches new password',
      },

      passwordRules: {
        title: 'Your password should contains:',
        rule1: '*At least 12 characters',
        rule2: '*At least 1 number',
        rule3: '*At least 1 special character',
      },

      invalidPassword: '*Invalid Password. Please change your password according to instructions',
      passwordNotMatch: '*Password does not match. Please review and correct accordingly',
    },

    resetBtn: 'Next',
  },

  footer: {
    troubleText: 'having trouble to reset password?',
    troubleBtnText: 'click here',
  },

  resetPasswordTroubleModal: {
    header: 'reset password assistance',
    body: 'For any assistance please contact your Customer Success Manager in the relevant communication channel. if this is an emergency, please call the emergency hotline provided to you by the Profero team.',
  },

  steps: {
    one: {
      number: '1.',
      description: 'MFA code',
    },

    two: {
      number: '2.',
      description: 'TOTP',
    },

    three: {
      number: '3.',
      description: 'Reset Password',
    },
  },

  resetSuccessToast: {
    message: 'reset password successfully',
  },

  resetFailedToast: {
    message: 'could not reset password',
  },
};

export default resetPassword;
