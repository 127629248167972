import * as actions from '../actions/incidents';

const initialState: State = {
  incidents: 0,
};

export const reducer = (state: State = initialState, action: actions.IncidentsTypes): State => {
  switch (action.type) {
    case actions.IncidentsActions.SetIncidentsCount:
      return { ...state, incidents: action.payload };
    default:
      return state;
  }
};

export type State = {
  incidents: number;
};
