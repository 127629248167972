import React from 'react';
import { Link } from 'react-router-dom';

import classes from './PButton.module.scss';
import { ThemeColors } from 'models/general';
import { ButtonType } from './PButton.model';

type Props = {
  className?: string;
  color: ThemeColors;
  disabled?: boolean;
  type: ButtonType;
  to?: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  dataTestid?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const PButtonView = (props: React.PropsWithChildren<Props>): JSX.Element => {
  return props.to && !props.disabled ? (
    <Link className={classes['link']} to={props.to}>
      <button
        ref={props.buttonRef}
        className={`${classes['btn']} ${classes[`btn--${props.color}`]} ${props.className || ''}`.trim()}
        type={props.type}
        disabled={props.disabled}
        data-testid={props.dataTestid}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    </Link>
  ) : (
    <button
      ref={props.buttonRef}
      className={`${classes['btn']} ${classes[`btn--${props.color}`]} ${props.className || ''}`.trim()}
      type={props.type}
      disabled={props.disabled}
      data-testid={props.dataTestid}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

PButtonView.displayName = 'PButtonView';
PButtonView.defaultProps = {};

export default PButtonView;
