const resetPasswordTOTP = {
  welcome: 'reset password',

  form: {
    title: 'Enter the 6 digits code provided for <strong>Rapid IR User</strong> from your authenticator app.',
    loginBtn: 'verify',
    correct: 'Code verified!',
    loging: 'Loging into your account',
    error: 'Incorrect MFA code'
  },

  footer: {
    troubleText: 'having trouble reset password?',
    troubleBtnText: 'click here',
  },

  resetPasswordTroubleModal: {
    header: 'reset password assistance',
    body: 'For any assistance please contact your Customer Success Manager in the relevant communication channel. If this is an emergency, please call the emergency hotline provided to you by the Profero team.',
  },

  exeededAttempts: {
    title: 'Unfortunately',
    body: '<strong>This session is terminated,</strong></br>due to too many attempts to enter wrong code</br>Please contact your representative in</br><strong>Profero</strong>for further assistance.',
  },
};

export default resetPasswordTOTP;
