export enum SensorsActions {
  SetSensorsHistoryData = '[sensoreHistoryData] Set sensors history data ',
}

export type SetSensorsHistoryData = {
  type: SensorsActions.SetSensorsHistoryData;
  payload: { name: string; value: string }[];
};

// Action creators

export const setSensorsHistoryData = (flags: { name: string; value: string }[]): SetSensorsHistoryData => {
  return {
    type: SensorsActions.SetSensorsHistoryData,
    payload: flags,
  };
};

export type SensorsTypes = SetSensorsHistoryData;
