const registration = {
  welcome: 'welcome to rapid-ir',
  subtitle: 'profero',

  form: {
    title: 'Create a password and edit your user name',

    fields: {
      full_name: {
        title: 'Full Name',
        placeholder: 'Enter Full Name Here',
      },

      email: {
        title: 'Email',
        placeholder: 'Enter Email Here',
      },

      phone_num: {
        title: 'Phone',
        placeholder: 'Enter Phone Here',
      },

      password: {
        title: 'Password',
        placeholder: 'Create Password',
      },

      reenter_password: {
        title: 'Repeat Password',
        placeholder: 'Repeat Password as above',
        validation: 'confirm password not matches new password',
      },

      passwordRules: {
        title: 'Your password should contains:',
        rule1: '*At least 12 characters',
        rule2: '*At least 1 number',
        rule3: '*At least 1 special character',
      },

      termsOfService: {
        text1: 'By checking this box you confirm that you read, understood,',
        text2: 'and accept the full Terms of Service.',
        tos: 'Terms of Use',
      },

      invalidPassword: '*Invalid Password. Please change your password according to instructions',
      passwordNotMatch: '*Password does not match. Please review and correct accordingly',
    },

    registerBtn: 'Next',
  },

  footer: {
    troubleText: 'having trouble to sign up?',
    troubleBtnText: 'click here',
  },

  loginTroubleModal: {
    header: 'having any troubles?',
    body: 'For any assistance please contact your Customer Success Manager in the relevant communication channel. if this is an emergency, please call the emergency hotline provided to you by the Profero team.',
  },

  invitationError: {
    title: 'Somthing went wrong',
    body: 'Please contact your customer support',
  },

  steps: {
    one: {
      number: '1.',
      description: 'MFA code',
    },

    two: {
      number: '2.',
      description: 'User Credentials',
    },

    three: {
      number: '3.',
      description: 'TOTP',
    },

    four: {
      number: '4.',
      description: 'Pending Approval',
    },
  },
};

export default registration;
