const reports = {
  title: 'reports',
  newReportBtn: 'add report',

  actions: {
    delete: {
      modal: {
        header: 'heads up: you are about to delete a report',
        body: 'You are about to delete a report by the name of: <strong>{{name}}</strong>.</br> <u>This action is irreversible.</u> </br><strong>Are you sure you want to delete it?</strong>',
        confirmText: 'yes, delete it',
        deleteText: 'cancel',
      },

      successToast: {
        message: 'Report deleted',
      },

      errorToast: {
        message: 'Could not delete report',
      },
    },
  },

  table: {
    headers: {
      title: 'report name',
      notes: 'date',

      reportType: {
        title: 'report type',

        selectionDropdown: {
          selectAll: 'select all',
          clearAll: 'clear all',
        },
      },

      tlp: {
        title: 'tlp',

        selectionDropdown: {
          selectAll: 'select all',
          clearAll: 'clear all',
        },
      },
    },

    extraData: {
      summaryTitle: 'report summary',
      filesTitle: 'attached files',

      actions: {
        download: {
          tooltipText: 'download file',
          download: 'file downloaded successfully',
        },

        edit: {
          tooltipText: 'edit report',
        },

        delete: {
          tooltipText: 'delete report',
        },

        copy: {
          tooltipText: 'copy download link',
          copied: 'report link copied successfully',
        },
      },
    },

    generalFilterText: 'Search in reports',
    moreInfo: 'more info',
  },

  form: {
    create: {
      title: 'Add New Report',

      fields: {
        title: {
          display: 'Report Name',
          placeholder: 'Add report name',
        },

        date: {
          display: 'Report Date',
          placeholder: 'Report date: {{name}}',
        },

        tags: {
          display: 'Tags',
          placeholder: 'Add tags',
        },

        uploadFile: {
          display: 'Upload File (max file size: 25 mb)',
        },

        description: {
          display: 'report summary (up to 350 charcters)',
        },

        notifications: {
          display: 'enable notifications',
        },

        notificationsImmediately: {
          display: 'send notifications immediately',
          info: "Selecting this option will disregard timezones when sending notifications. Use this option when you want notifications to be delivered immediately, regardless of the recipient's location or timezone differences.",
        },

        reportType: {
          display: 'report type',
          placeholder: 'Select a report type',

          reportTypeOptions: {
            compromiseAssessment: 'Compromise Assessment',
            incidentReport: 'Incident Report',
            intelligence: 'Intelligence',
            customReport: 'Custom Report',
          },
        },

        tlp: {
          display: 'tlp',
          placeholder: 'Select a TLP level',

          tlpOptions: {
            red: 'RED',
            amber: 'AMBER',
            green: 'GREEN',
            clear: 'CLEAR',
            amberStrict: 'AMBER STRICT',
          },
        },
      },

      footer: {
        confirm: 'Add Report',
        cancel: 'Cancel',
      },

      successToast: {
        message: 'New report added',
      },

      errorToast: {
        message: 'Could not add report',
      },
    },

    edit: {
      title: 'Edit Report',

      fields: {
        title: {
          display: 'Report Name',
          placeholder: 'Enter title',
        },

        date: {
          display: 'Report Date',
          placeholder: 'Report date: {{name}}',
        },

        tags: {
          display: 'Tags',
          placeholder: 'Add tags',
        },

        uploadFile: {
          display: 'Upload File (max file size: 25 mb)',
        },

        description: {
          display: 'report summary (up to 350 charcters)',
        },

        reportType: {
          display: 'report type',
          placeholder: 'Select a report type',

          reportTypeOptions: {
            compromiseAssessment: 'Compromise Assessment',
            incidentReport: 'Incident Report',
            intelligence: 'Intelligence',
            customReport: 'Custom Report',
          },
        },

        tlp: {
          display: 'tlp',
          placeholder: 'Select a TLP Level',

          tlpOptions: {
            red: 'RED',
            amber: 'AMBER',
            green: 'GREEN',
            clear: 'CLEAR',
            amberStrict: 'AMBER STRICT',
          },
        },
      },

      footer: {
        confirm: 'Save',
        cancel: 'Cancel',
      },

      successToast: {
        message: 'Edit Successful',
      },

      errorToast: {
        message: 'Could not edit report',
      },
    },
  },

  tags: {
    errorToast: {
      message: 'no tags data',
    },
  },
};

export default reports;
