const warp = {
  title: 'warp status',

  table: {
    generalFilterText: 'Search',
    moreInfo: 'more info',
    paginationText: 'files',
    refresh: 'refresh table',
    clear: 'clear filter',
    search: 'Search files by dates',

    headers: {
      filesize: 'file size',
      filename: 'file name',
      createdAt: 'timestamp',
      percentage: 'progress %',
    },

    extraData: {
      title: 'additional info',

      details: {
        filepath: 'Filepath:',
        integrity: 'Integrity:',
        ipaddress: 'Ip address:',
        filesize: 'File size:',
        status: 'Status:',
        createdAt: 'Created at:',
        updatedAt: 'Updated at:',
        yetToDownload: 'Yet to download:',
        inProgress: 'In progress:',
        error: 'error:',
        inprogress_chunks: 'In progress chunks:',
        error_chunks: 'Error chunks:',
        estimatied_time: 'Estimatied time:',
        priority: 'Priority:',
        completed_chunks: 'Completed chunks:',
        total_chunks: 'Total chunks:',
        complete: 'Complete',
      },
    },
  },
};

export default warp;
