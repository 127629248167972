type IAppRouteFallback = {
  type: AppRouteType.Fallback;
  path: string;
  to: string;
};

type IAppRoutePage = {
  type: AppRouteType.Page;
  path: string;
  pageName?: string;
  component?: React.FC;
  jsx?: JSX.Element;
};

export type IAppRoute = (IAppRouteFallback | IAppRoutePage) & { children?: IAppRoute[] | null };

export enum AppRouteType {
  Fallback,
  Page,
}
