export enum IncidentsActions {
  SetIncidentsCount = '[incidents] Set incidents count',
}

export type SetIncidentsCount = {
  type: IncidentsActions.SetIncidentsCount;
  payload: number;
};

// Action creators

export const setIncidentsCount = (incidents: number): SetIncidentsCount => {
  return {
    type: IncidentsActions.SetIncidentsCount,
    payload: incidents,
  };
};

export type IncidentsTypes = SetIncidentsCount;
