import { IFlag } from 'models/flag';

export enum FeatureFlagsActions {
  SetFeatureFlags = '[featureFlags] Set feature flags',
}

export type SetFeatureFlags = {
  type: FeatureFlagsActions.SetFeatureFlags;
  payload: IFlag[];
};

// Action creators

export const setFeatureFlags = (flags: IFlag[]): SetFeatureFlags => {
  return {
    type: FeatureFlagsActions.SetFeatureFlags,
    payload: flags,
  };
};

export type FeatureFlagsTypes = SetFeatureFlags;
