import { IPermission } from './permission';

export type IUser = {
  username: string;
  sessionid: string;
  oid: string;
  org_name: string;
  space: string;
  sub: string;
  exp: number;
  iat: number;
  scope: IPermission[];
};

export type IUserDetails = {
  full_name: string;
  phone_num: string;
  username: string;
  time_zone: string;
};

export class User {
  private _username: string;
  private _sessionid: string;
  private _oid: string;
  private _org_name: string;
  private _space: string;
  private _sub: string;
  private _exp: number;
  private _iat: number;
  private _scope: IPermission[];

  constructor(userData: IUser) {
    this._username = userData.username;
    this._sessionid = userData.sessionid;
    this._oid = userData.oid;
    this._org_name = userData.org_name;
    this._space = userData.space;
    this._sub = userData.sub;
    this._exp = userData.exp;
    this._iat = userData.iat;
    this._scope = userData.scope;
  }

  public getName(): string {
    return this._username;
  }
}
