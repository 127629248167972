const resetPasswordFullRecoveryTOTP = {
  welcome: 'reset password',

  form: {
    loginBtn: 'verify',
    correct: 'Code verified!',
    loging: 'Loging into your account',
    error: 'Incorrect MFA code'
  },

  footer: {
    troubleText: 'having trouble reset password?',
    troubleBtnText: 'click here',
  },

  resetPasswordTroubleModal: {
    header: 'reset password assistance',
    body: 'For any assistance please contact your Customer Success Manager in the relevant communication channel. if this is an emergency, please call the emergency hotline provided to you by the Profero team.',
  },

  exeededAttempts: {
    title: 'Unfortunately',
    sessionTerminate: 'This session is terminated,',
    body: 'due to too many attempts to enter wrong code</br>Please contact your representative in</br><strong>Profero</strong> for further assistance.',
  },

  viewManualCode: {
    header: 'totp manual conecting code',
    body: 'This is the manual code to connect the authenticator app to RAPID-IR:',
    copy: 'value copied successfully',
  },

  invitationError: {
    title: 'Somthing went wrong',
    body: 'Please contact your customer support',
  },

  steps: {
    one: {
      number: '1.',
      description: 'MFA code',
    },

    two: {
      number: '2.',
      description: 'TOTP',
    },

    three: {
      number: '3.',
      description: 'Reset Password',
    },
  },

  installSteps: {
    step1line1: '1. Scan the new QR code',
    step1line2: 'scan the new QR code to the Authenticator',
    cantSeeQR: '*Can’t scan the QR?',
    viewManualCode: 'View manual code',
    verify: '2. Verify your account',
    enterCode: 'Enter the 6 digits code provided for <strong>Rapid IR User</strong> from your authenticator app.',
  },
};

export default resetPasswordFullRecoveryTOTP;
