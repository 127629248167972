const upload = {
  title: 'warp usage',

  notes: {
    title: 'before installing',
    text1: 'Please note the following prior to using warp:',
    text2: 'Warp will do its best to make use of all available bandwidth',
    text3: 'If Warp is interrupted you can re-run the program again to resume',
    text4: 'Once the program is finished, you will get feedback on screen',
    text5:
      'Prior to the uploading starting there is a forensic stage that might take awhile. Once it’s completed, the program will notify that it has started uploading the various chunks.',
  },

  usage: {
    title: 'instaling Instructions',
    text1: 'Open a terminal',
    text2: 'Navigate to the directory/folder where the file to be uploaded is located (optional)',
    text3: 'Copy the following text into the terminal using the copy button',
    text4: 'Replace INPUT_FILE with the filename you want to upload',
    text5: 'Execute the program',
    windows: 'Windows',
    macos: 'MacOs',
    linunx: 'Linux',
    valueCopied: 'value copied successfully',
  },
};

export default upload;
