import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore, Reducer } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { AppState } from '../models/app-store';
import { watchAuth } from './sagas/auth';
import * as fromOrgs from './reducers/orgs';
import * as fromAuth from './reducers/auth';
import * as fromUI from './reducers/ui';
import * as fromFeatureFlags from './reducers/feature-flags';
import * as fromSensors from './reducers/sensors';
import * as fromIncidents from './reducers/incidents';
import * as fromLayoutFlags from './reducers/layout-flags';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers: typeof compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const rootReducer: Reducer<AppState> = combineReducers({
  auth: fromAuth.reducer,
  ui: fromUI.reducer,
  orgs: fromOrgs.reducer,
  featureFlags: fromFeatureFlags.reducer,
  layoutFlags: fromLayoutFlags.reducer,
  sensors: fromSensors.reducer,
  incidents: fromIncidents.reducer,
});

const storeRoot = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(watchAuth);

export const store = storeRoot;
