const health = {
  title: 'diagnostics',
  search: 'Search',

  localNetwork: {
    title: 'local network',
    investigator: 'Sensor',
    defaultDns: 'Default DNS',
  },

  publicInternet: {
    title: 'public internet',
    publicity: 'Publicy available service',
  },

  profero: {
    title: 'profero',
    portal: 'Portal',
    api: 'API',
    warp: 'Warp',
    investigator: 'Investigator',
  },

  result: {
    statuscode: 'Status code',
    noError: 'No connection error found',
    issueDetected: 'issues detected',
  },
};

export default health;
