const auditOrg = {
  title: 'audit org',

  filters: {
    selectCategory: {
      placeholder: 'Select category',
    },

    selectUser: {
      placeholder: 'Select user',
    },

    str: {
      placehlder: 'Enter free text',
    },

    serachBtn: 'search',
    clearBtn: 'clear filters',
  },

  table: {
    headers: {
      actionType: 'action type',
      category: 'category',
      createdAt: 'time',
      msg: 'message',
      userName: 'user name',
      selectAll: 'select all',
      clearAll: 'clear all',
      export: 'export',
    },

    noResults: 'No Results',

    bottom: {
      loadMore: 'Load More',
    },
  },
};

export default auditOrg;
